import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { DocumentTable } from '../../../nucleus/services/documentService/types';

@Injectable({
  providedIn: 'root',
})
export class DocumentTableViewerService implements OnDestroy {
  private readonly selectedTable$: ReplaySubject<DocumentTable>;

  constructor() {
    this.selectedTable$ = new ReplaySubject(1);
  }

  ngOnDestroy(): void {
    this.selectedTable$.complete();
  }

  getSelectedTable(): Observable<DocumentTable> {
    return this.selectedTable$.asObservable();
  }

  /**
   * Select Document Table.
   *
   * Use this when selecting a table in a Biologics Annotator Result/Comparisons Result.
   * This abstracts the table selection logic so it can be used for other responsibilities of the sequences table (Labels etc).
   * The selectedTable can be listened to via getSelectedTable().
   *
   * @param table name of the document table that is selected.
   */
  setSelectedTable(table: DocumentTable): void {
    this.selectedTable$.next(table);
  }
}
