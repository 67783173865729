@for (heatmap of heatmaps$ | async; track heatmap; let i = $index) {
  <bx-chart
    #chart
    [chartOptions]="heatmap.chartOptions"
    [title]="i === 0 ? titleOptions : undefined"
    [tooltip]="tooltipOptions"
    [xAxis]="heatmap.xAxisOptions"
    [yAxis]="yAxisOptions"
    [colorAxis]="colorAxis"
    [legend]="legendOptions"
    [series]="heatmap.series"
    [plotOptions]="plotOptions"
    (chartLoaded)="onChartLoad($event)"
    class="d-block overflow-none"
  >
  </bx-chart>
}
