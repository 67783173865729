export enum ResultDocumentFieldEnum {
  TOTAL_CODONS = 'totalCodons',
  LENGTH = 'Length',
  CODON = 'Codon',
  AMINO = 'Amino Acid',
  TOTAL = 'Total',
  TOTAL_SEQS = 'Total Sequences',
  REGION = 'Region',
  POSITION = 'Position',
  ROW_NUMBER = 'row_number',
}
