@if (
  {
    canWrite: canWrite$ | async,
    labels: labels$ | async,
    labelIDs: labelIDs$ | async,
  };
  as vm
) {
  @if (vm.canWrite) {
    <div class="add-label">
      <button
        title="Add labels"
        type="button"
        class="btn btn-sm py-0 px-2"
        [ngbPopover]="labelPicker"
        (shown)="onPopoverShown()"
        (hidden)="onPopoverHidden()"
        #labelPickerPopover="ngbPopover"
        autoClose="outside"
        placement="bottom"
        popoverClass="w-static-1"
        data-toggle="popover"
        data-trigger="focus"
        data-spec="add-label-btn"
      >
        <strong>+</strong>
      </button>
    </div>
  }
  <ng-template #labelPicker>
    <bx-label-single-select
      class="mt-1"
      [initialSelection]="vm.labelIDs"
      (labelSelected)="addLabel($event)"
      data-spec="select-label"
    ></bx-label-single-select>
  </ng-template>
  <div class="d-flex align-items-center">
    @for (label of vm.labels; track label) {
      <bx-label-badge
        class="me-1"
        [readonly]="!vm.canWrite"
        [labelName]="label.name"
        [colorHex]="label.color"
        (removeLabel)="removeLabel(label)"
      ></bx-label-badge>
    }
  </div>
}
