import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy } from '@angular/core';
import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { IHeaderGroupParams } from '@ag-grid-community/core';
import { switchMap, take } from 'rxjs/operators';
import { DocumentTableService } from './document-table.service';
import { DocumentTableViewerService } from './document-table-viewer.service';
import { Subscription } from 'rxjs';

// TODO Make this generic instead of being tied to assay data.
@Component({
  selector: 'bx-joined-table-header',
  templateUrl: './joined-table-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class JoinedTableHeaderComponent implements IHeaderGroupAngularComp, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex align-items-center';
  params: IHeaderGroupParams;

  private subscriptions = new Subscription();

  constructor(
    private documentTableService: DocumentTableService,
    private documentTableViewerService: DocumentTableViewerService,
  ) {}

  agInit(params: IHeaderGroupParams): void {
    this.params = params;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  remove(): void {
    this.subscriptions.add(
      this.documentTableViewerService
        .getSelectedTable()
        .pipe(
          take(1),
          switchMap((selectedTable) => {
            return this.documentTableService.removeAssayTableAction(
              selectedTable,
              this.params.columnGroup.getGroupId(),
              this.params.displayName,
            );
          }),
        )
        .subscribe(),
    );
  }
}
