/*
 Highmaps JS v9.3.3 (2022-02-01)

 (c) 2009-2021 Torstein Honsi

 License: www.highcharts.com/license
*/
'use strict';

(function (b) {
  "object" === typeof module && module.exports ? (b["default"] = b, module.exports = b) : "function" === typeof define && define.amd ? define("highcharts/modules/heatmap", ["highcharts"], function (x) {
    b(x);
    b.Highcharts = x;
    return b;
  }) : b("undefined" !== typeof Highcharts ? Highcharts : void 0);
})(function (b) {
  function x(b, h, z, n) {
    b.hasOwnProperty(h) || (b[h] = n.apply(null, z));
  }
  b = b ? b._modules : {};
  x(b, "Core/Axis/Color/ColorAxisComposition.js", [b["Core/Color/Color.js"], b["Core/Utilities.js"]], function (b, h) {
    var r = b.parse,
      n = h.addEvent,
      y = h.extend,
      t = h.merge,
      A = h.pick,
      C = h.splat,
      m;
    (function (f) {
      function b() {
        var c = this,
          a = this.options;
        this.colorAxis = [];
        a.colorAxis && (a.colorAxis = C(a.colorAxis), a.colorAxis.forEach(function (a, d) {
          a.index = d;
          new k(c, a);
        }));
      }
      function h(a) {
        var c = this,
          d = function (d) {
            d = a.allItems.indexOf(d);
            -1 !== d && (c.destroyItem(a.allItems[d]), a.allItems.splice(d, 1));
          },
          e = [],
          p,
          k;
        (this.chart.colorAxis || []).forEach(function (a) {
          (p = a.options) && p.showInLegend && (p.dataClasses && p.visible ? e = e.concat(a.getDataClassLegendSymbols()) : p.visible && e.push(a), a.series.forEach(function (a) {
            if (!a.options.showInLegend || p.dataClasses) "point" === a.options.legendType ? a.points.forEach(function (a) {
              d(a);
            }) : d(a);
          }));
        });
        for (k = e.length; k--;) a.allItems.unshift(e[k]);
      }
      function m(a) {
        a.visible && a.item.legendColor && a.item.legendSymbol.attr({
          fill: a.item.legendColor
        });
      }
      function u() {
        var a = this.chart.colorAxis;
        a && a.forEach(function (a, c, d) {
          a.update({}, d);
        });
      }
      function w() {
        (this.chart.colorAxis && this.chart.colorAxis.length || this.colorAttribs) && this.translateColors();
      }
      function q() {
        var a = this.axisTypes;
        a ? -1 === a.indexOf("colorAxis") && a.push("colorAxis") : this.axisTypes = ["colorAxis"];
      }
      function E(a) {
        var c = this,
          d = a ? "show" : "hide";
        c.visible = c.options.visible = !!a;
        ["graphic", "dataLabel"].forEach(function (a) {
          if (c[a]) c[a][d]();
        });
        this.series.buildKDTree();
      }
      function g() {
        var a = this,
          c = this.options.nullColor,
          d = this.colorAxis,
          e = this.colorKey;
        (this.data.length ? this.data : this.points).forEach(function (k) {
          var l = k.getNestedProperty(e);
          (l = k.options.color || (k.isNull || null === k.value ? c : d && "undefined" !== typeof l ? d.toColor(l, k) : k.color || a.color)) && k.color !== l && (k.color = l, "point" === a.options.legendType && k.legendItem && a.chart.legend.colorizeItem(k, k.visible));
        });
      }
      function e(a) {
        var c = a.prototype.createAxis;
        a.prototype.createAxis = function (a, d) {
          if ("colorAxis" !== a) return c.apply(this, arguments);
          var e = new k(this, t(d.axis, {
            index: this[a].length,
            isX: !1
          }));
          this.isDirtyLegend = !0;
          this.axes.forEach(function (a) {
            a.series = [];
          });
          this.series.forEach(function (a) {
            a.bindAxes();
            a.isDirtyData = !0;
          });
          A(d.redraw, !0) && this.redraw(d.animation);
          return e;
        };
      }
      function d() {
        this.elem.attr("fill", r(this.start).tweenTo(r(this.end), this.pos), void 0, !0);
      }
      function c() {
        this.elem.attr("stroke", r(this.start).tweenTo(r(this.end), this.pos), void 0, !0);
      }
      var a = [],
        k;
      f.compose = function (p, l, B, f, v) {
        k || (k = p);
        -1 === a.indexOf(l) && (a.push(l), p = l.prototype, p.collectionsWithUpdate.push("colorAxis"), p.collectionsWithInit.colorAxis = [p.addColorAxis], n(l, "afterGetAxes", b), e(l));
        -1 === a.indexOf(B) && (a.push(B), l = B.prototype, l.fillSetter = d, l.strokeSetter = c);
        -1 === a.indexOf(f) && (a.push(f), n(f, "afterGetAllItems", h), n(f, "afterColorizeItem", m), n(f, "afterUpdate", u));
        -1 === a.indexOf(v) && (a.push(v), y(v.prototype, {
          optionalAxis: "colorAxis",
          translateColors: g
        }), y(v.prototype.pointClass.prototype, {
          setVisible: E
        }), n(v, "afterTranslate", w), n(v, "bindAxes", q));
      };
      f.pointSetVisible = E;
    })(m || (m = {}));
    return m;
  });
  x(b, "Core/Axis/Color/ColorAxisDefaults.js", [], function () {
    return {
      lineWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      gridLineWidth: 1,
      tickPixelInterval: 72,
      startOnTick: !0,
      endOnTick: !0,
      offset: 0,
      marker: {
        animation: {
          duration: 50
        },
        width: .01,
        color: "#999999"
      },
      labels: {
        overflow: "justify",
        rotation: 0
      },
      minColor: "#e6ebf5",
      maxColor: "#003399",
      tickLength: 5,
      showInLegend: !0
    };
  });
  x(b, "Core/Axis/Color/ColorAxis.js", [b["Core/Axis/Axis.js"], b["Core/Color/Color.js"], b["Core/Axis/Color/ColorAxisComposition.js"], b["Core/Axis/Color/ColorAxisDefaults.js"], b["Core/Globals.js"], b["Core/Legend/LegendSymbol.js"], b["Core/Series/SeriesRegistry.js"], b["Core/Utilities.js"]], function (b, h, z, n, y, t, A, C) {
    var m = this && this.__extends || function () {
        var f = function (g, e) {
          f = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (d, c) {
            d.__proto__ = c;
          } || function (d, c) {
            for (var a in c) c.hasOwnProperty(a) && (d[a] = c[a]);
          };
          return f(g, e);
        };
        return function (g, e) {
          function d() {
            this.constructor = g;
          }
          f(g, e);
          g.prototype = null === e ? Object.create(e) : (d.prototype = e.prototype, new d());
        };
      }(),
      f = h.parse,
      v = y.noop,
      r = A.series,
      D = C.extend,
      u = C.isNumber,
      w = C.merge,
      q = C.pick;
    h = function (b) {
      function g(e, d) {
        var c = b.call(this, e, d) || this;
        c.beforePadding = !1;
        c.chart = void 0;
        c.coll = "colorAxis";
        c.dataClasses = void 0;
        c.legendItem = void 0;
        c.legendItems = void 0;
        c.name = "";
        c.options = void 0;
        c.stops = void 0;
        c.visible = !0;
        c.init(e, d);
        return c;
      }
      m(g, b);
      g.compose = function (e, d, c, a) {
        z.compose(g, e, d, c, a);
      };
      g.prototype.init = function (e, d) {
        var c = e.options.legend || {},
          a = d.layout ? "vertical" !== d.layout : "vertical" !== c.layout,
          k = d.visible;
        c = w(g.defaultColorAxisOptions, d, {
          showEmpty: !1,
          title: null,
          visible: c.enabled && !1 !== k
        });
        this.coll = "colorAxis";
        this.side = d.side || a ? 2 : 1;
        this.reversed = d.reversed || !a;
        this.opposite = !a;
        b.prototype.init.call(this, e, c);
        this.userOptions.visible = k;
        d.dataClasses && this.initDataClasses(d);
        this.initStops();
        this.horiz = a;
        this.zoomEnabled = !1;
      };
      g.prototype.initDataClasses = function (e) {
        var d = this.chart,
          c = this.options,
          a = e.dataClasses.length,
          k,
          b = 0,
          l = d.options.chart.colorCount;
        this.dataClasses = k = [];
        this.legendItems = [];
        (e.dataClasses || []).forEach(function (e, g) {
          e = w(e);
          k.push(e);
          if (d.styledMode || !e.color) "category" === c.dataClassColor ? (d.styledMode || (g = d.options.colors, l = g.length, e.color = g[b]), e.colorIndex = b, b++, b === l && (b = 0)) : e.color = f(c.minColor).tweenTo(f(c.maxColor), 2 > a ? .5 : g / (a - 1));
        });
      };
      g.prototype.hasData = function () {
        return !!(this.tickPositions || []).length;
      };
      g.prototype.setTickPositions = function () {
        if (!this.dataClasses) return b.prototype.setTickPositions.call(this);
      };
      g.prototype.initStops = function () {
        this.stops = this.options.stops || [[0, this.options.minColor], [1, this.options.maxColor]];
        this.stops.forEach(function (e) {
          e.color = f(e[1]);
        });
      };
      g.prototype.setOptions = function (e) {
        b.prototype.setOptions.call(this, e);
        this.options.crosshair = this.options.marker;
      };
      g.prototype.setAxisSize = function () {
        var e = this.legendSymbol,
          d = this.chart,
          c = d.options.legend || {},
          a,
          k;
        e ? (this.left = c = e.attr("x"), this.top = a = e.attr("y"), this.width = k = e.attr("width"), this.height = e = e.attr("height"), this.right = d.chartWidth - c - k, this.bottom = d.chartHeight - a - e, this.len = this.horiz ? k : e, this.pos = this.horiz ? c : a) : this.len = (this.horiz ? c.symbolWidth : c.symbolHeight) || g.defaultLegendLength;
      };
      g.prototype.normalizedValue = function (e) {
        this.logarithmic && (e = this.logarithmic.log2lin(e));
        return 1 - (this.max - e) / (this.max - this.min || 1);
      };
      g.prototype.toColor = function (e, d) {
        var c = this.dataClasses,
          a = this.stops,
          k;
        if (c) for (k = c.length; k--;) {
          var b = c[k];
          var l = b.from;
          a = b.to;
          if (("undefined" === typeof l || e >= l) && ("undefined" === typeof a || e <= a)) {
            var g = b.color;
            d && (d.dataClass = k, d.colorIndex = b.colorIndex);
            break;
          }
        } else {
          e = this.normalizedValue(e);
          for (k = a.length; k-- && !(e > a[k][0]););
          l = a[k] || a[k + 1];
          a = a[k + 1] || l;
          e = 1 - (a[0] - e) / (a[0] - l[0] || 1);
          g = l.color.tweenTo(a.color, e);
        }
        return g;
      };
      g.prototype.getOffset = function () {
        var e = this.legendGroup,
          d = this.chart.axisOffset[this.side];
        e && (this.axisParent = e, b.prototype.getOffset.call(this), this.added || (this.added = !0, this.labelLeft = 0, this.labelRight = this.width), this.chart.axisOffset[this.side] = d);
      };
      g.prototype.setLegendColor = function () {
        var e = this.reversed,
          d = e ? 1 : 0;
        e = e ? 0 : 1;
        d = this.horiz ? [d, 0, e, 0] : [0, e, 0, d];
        this.legendColor = {
          linearGradient: {
            x1: d[0],
            y1: d[1],
            x2: d[2],
            y2: d[3]
          },
          stops: this.stops
        };
      };
      g.prototype.drawLegendSymbol = function (e, d) {
        var c = e.padding,
          a = e.options,
          k = this.horiz,
          b = q(a.symbolWidth, k ? g.defaultLegendLength : 12),
          l = q(a.symbolHeight, k ? 12 : g.defaultLegendLength),
          f = q(a.labelPadding, k ? 16 : 30);
        a = q(a.itemDistance, 10);
        this.setLegendColor();
        d.legendSymbol = this.chart.renderer.rect(0, e.baseline - 11, b, l).attr({
          zIndex: 1
        }).add(d.legendGroup);
        this.legendItemWidth = b + c + (k ? a : f);
        this.legendItemHeight = l + c + (k ? f : 0);
      };
      g.prototype.setState = function (e) {
        this.series.forEach(function (d) {
          d.setState(e);
        });
      };
      g.prototype.setVisible = function () {};
      g.prototype.getSeriesExtremes = function () {
        var e = this.series,
          d = e.length,
          c;
        this.dataMin = Infinity;
        for (this.dataMax = -Infinity; d--;) {
          var a = e[d];
          var k = a.colorKey = q(a.options.colorKey, a.colorKey, a.pointValKey, a.zoneAxis, "y");
          var b = a.pointArrayMap;
          var l = a[k + "Min"] && a[k + "Max"];
          if (a[k + "Data"]) var g = a[k + "Data"];else if (b) {
            g = [];
            b = b.indexOf(k);
            var f = a.yData;
            if (0 <= b && f) for (c = 0; c < f.length; c++) g.push(q(f[c][b], f[c]));
          } else g = a.yData;
          l ? (a.minColorValue = a[k + "Min"], a.maxColorValue = a[k + "Max"]) : (g = r.prototype.getExtremes.call(a, g), a.minColorValue = g.dataMin, a.maxColorValue = g.dataMax);
          "undefined" !== typeof a.minColorValue && (this.dataMin = Math.min(this.dataMin, a.minColorValue), this.dataMax = Math.max(this.dataMax, a.maxColorValue));
          l || r.prototype.applyExtremes.call(a);
        }
      };
      g.prototype.drawCrosshair = function (e, d) {
        var c = d && d.plotX,
          a = d && d.plotY,
          k = this.pos,
          g = this.len;
        if (d) {
          var f = this.toPixels(d.getNestedProperty(d.series.colorKey));
          f < k ? f = k - 2 : f > k + g && (f = k + g + 2);
          d.plotX = f;
          d.plotY = this.len - f;
          b.prototype.drawCrosshair.call(this, e, d);
          d.plotX = c;
          d.plotY = a;
          this.cross && !this.cross.addedToColorAxis && this.legendGroup && (this.cross.addClass("highcharts-coloraxis-marker").add(this.legendGroup), this.cross.addedToColorAxis = !0, this.chart.styledMode || "object" !== typeof this.crosshair || this.cross.attr({
            fill: this.crosshair.color
          }));
        }
      };
      g.prototype.getPlotLinePath = function (e) {
        var d = this.left,
          c = e.translatedValue,
          a = this.top;
        return u(c) ? this.horiz ? [["M", c - 4, a - 6], ["L", c + 4, a - 6], ["L", c, a], ["Z"]] : [["M", d, c], ["L", d - 6, c + 6], ["L", d - 6, c - 6], ["Z"]] : b.prototype.getPlotLinePath.call(this, e);
      };
      g.prototype.update = function (e, d) {
        var c = this.chart.legend;
        this.series.forEach(function (a) {
          a.isDirtyData = !0;
        });
        (e.dataClasses && c.allItems || this.dataClasses) && this.destroyItems();
        b.prototype.update.call(this, e, d);
        this.legendItem && (this.setLegendColor(), c.colorizeItem(this, !0));
      };
      g.prototype.destroyItems = function () {
        var e = this.chart;
        this.legendItem ? e.legend.destroyItem(this) : this.legendItems && this.legendItems.forEach(function (d) {
          e.legend.destroyItem(d);
        });
        e.isDirtyLegend = !0;
      };
      g.prototype.destroy = function () {
        this.chart.isDirtyLegend = !0;
        this.destroyItems();
        b.prototype.destroy.apply(this, [].slice.call(arguments));
      };
      g.prototype.remove = function (e) {
        this.destroyItems();
        b.prototype.remove.call(this, e);
      };
      g.prototype.getDataClassLegendSymbols = function () {
        var e = this,
          d = e.chart,
          c = e.legendItems,
          a = d.options.legend,
          k = a.valueDecimals,
          b = a.valueSuffix || "",
          f;
        c.length || e.dataClasses.forEach(function (a, g) {
          var l = a.from,
            B = a.to,
            h = d.numberFormatter,
            p = !0;
          f = "";
          "undefined" === typeof l ? f = "< " : "undefined" === typeof B && (f = "> ");
          "undefined" !== typeof l && (f += h(l, k) + b);
          "undefined" !== typeof l && "undefined" !== typeof B && (f += " - ");
          "undefined" !== typeof B && (f += h(B, k) + b);
          c.push(D({
            chart: d,
            name: f,
            options: {},
            drawLegendSymbol: t.drawRectangle,
            visible: !0,
            setState: v,
            isDataClass: !0,
            setVisible: function () {
              p = e.visible = !p;
              e.series.forEach(function (a) {
                a.points.forEach(function (a) {
                  a.dataClass === g && a.setVisible(p);
                });
              });
              d.legend.colorizeItem(this, p);
            }
          }, a));
        });
        return c;
      };
      g.defaultColorAxisOptions = n;
      g.defaultLegendLength = 200;
      g.keepProps = ["legendGroup", "legendItemHeight", "legendItemWidth", "legendItem", "legendSymbol"];
      return g;
    }(b);
    Array.prototype.push.apply(b.keepProps, h.keepProps);
    "";
    return h;
  });
  x(b, "Series/ColorMapMixin.js", [b["Core/Globals.js"], b["Core/Series/Point.js"], b["Core/Utilities.js"]], function (b, h, z) {
    var r = b.noop;
    b = b.seriesTypes;
    var y = z.defined;
    z = z.addEvent;
    z(h, "afterSetState", function (b) {
      this.moveToTopOnHover && this.graphic && this.graphic.attr({
        zIndex: b && "hover" === b.state ? 1 : 0
      });
    });
    return {
      PointMixin: {
        dataLabelOnNull: !0,
        moveToTopOnHover: !0,
        isValid: function () {
          return null !== this.value && Infinity !== this.value && -Infinity !== this.value;
        }
      },
      SeriesMixin: {
        pointArrayMap: ["value"],
        axisTypes: ["xAxis", "yAxis", "colorAxis"],
        trackerGroups: ["group", "markerGroup", "dataLabelsGroup"],
        getSymbol: r,
        parallelArrays: ["x", "y", "value"],
        colorKey: "value",
        pointAttribs: b.column.prototype.pointAttribs,
        colorAttribs: function (b) {
          var h = {};
          !y(b.color) || b.state && "normal" !== b.state || (h[this.colorProp || "fill"] = b.color);
          return h;
        }
      }
    };
  });
  x(b, "Series/Heatmap/HeatmapPoint.js", [b["Core/Series/SeriesRegistry.js"], b["Core/Utilities.js"]], function (b, h) {
    var r = this && this.__extends || function () {
        var b = function (h, f) {
          b = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (b, f) {
            b.__proto__ = f;
          } || function (b, f) {
            for (var h in f) f.hasOwnProperty(h) && (b[h] = f[h]);
          };
          return b(h, f);
        };
        return function (h, f) {
          function v() {
            this.constructor = h;
          }
          b(h, f);
          h.prototype = null === f ? Object.create(f) : (v.prototype = f.prototype, new v());
        };
      }(),
      n = h.clamp,
      y = h.defined,
      t = h.extend,
      A = h.pick;
    b = function (b) {
      function h() {
        var f = null !== b && b.apply(this, arguments) || this;
        f.options = void 0;
        f.series = void 0;
        f.value = void 0;
        f.x = void 0;
        f.y = void 0;
        return f;
      }
      r(h, b);
      h.prototype.applyOptions = function (f, h) {
        f = b.prototype.applyOptions.call(this, f, h);
        f.formatPrefix = f.isNull || null === f.value ? "null" : "point";
        return f;
      };
      h.prototype.getCellAttributes = function () {
        var b = this.series,
          h = b.options,
          r = (h.colsize || 1) / 2,
          m = (h.rowsize || 1) / 2,
          u = b.xAxis,
          w = b.yAxis,
          q = this.options.marker || b.options.marker;
        b = b.pointPlacementToXValue();
        var t = A(this.pointPadding, h.pointPadding, 0),
          g = {
            x1: n(Math.round(u.len - (u.translate(this.x - r, !1, !0, !1, !0, -b) || 0)), -u.len, 2 * u.len),
            x2: n(Math.round(u.len - (u.translate(this.x + r, !1, !0, !1, !0, -b) || 0)), -u.len, 2 * u.len),
            y1: n(Math.round(w.translate(this.y - m, !1, !0, !1, !0) || 0), -w.len, 2 * w.len),
            y2: n(Math.round(w.translate(this.y + m, !1, !0, !1, !0) || 0), -w.len, 2 * w.len)
          };
        [["width", "x"], ["height", "y"]].forEach(function (b) {
          var d = b[0];
          b = b[1];
          var c = b + "1",
            a = b + "2",
            e = Math.abs(g[c] - g[a]),
            f = q && q.lineWidth || 0,
            h = Math.abs(g[c] + g[a]) / 2;
          d = q && q[d];
          y(d) && d < e && (d = d / 2 + f / 2, g[c] = h - d, g[a] = h + d);
          t && ("y" === b && (c = a, a = b + "1"), g[c] += t, g[a] -= t);
        });
        return g;
      };
      h.prototype.haloPath = function (b) {
        if (!b) return [];
        var f = this.shapeArgs;
        return ["M", f.x - b, f.y - b, "L", f.x - b, f.y + f.height + b, f.x + f.width + b, f.y + f.height + b, f.x + f.width + b, f.y - b, "Z"];
      };
      h.prototype.isValid = function () {
        return Infinity !== this.value && -Infinity !== this.value;
      };
      return h;
    }(b.seriesTypes.scatter.prototype.pointClass);
    t(b.prototype, {
      dataLabelOnNull: !0,
      moveToTopOnHover: !0,
      ttBelow: !1
    });
    return b;
  });
  x(b, "Series/Heatmap/HeatmapSeries.js", [b["Core/Color/Color.js"], b["Series/ColorMapMixin.js"], b["Series/Heatmap/HeatmapPoint.js"], b["Core/Legend/LegendSymbol.js"], b["Core/Series/SeriesRegistry.js"], b["Core/Renderer/SVG/SVGRenderer.js"], b["Core/Utilities.js"]], function (b, h, x, n, y, t, A) {
    var r = this && this.__extends || function () {
        var b = function (d, c) {
          b = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, c) {
            a.__proto__ = c;
          } || function (a, c) {
            for (var b in c) c.hasOwnProperty(b) && (a[b] = c[b]);
          };
          return b(d, c);
        };
        return function (d, c) {
          function a() {
            this.constructor = d;
          }
          b(d, c);
          d.prototype = null === c ? Object.create(c) : (a.prototype = c.prototype, new a());
        };
      }(),
      m = y.series,
      f = y.seriesTypes,
      v = f.column,
      z = f.scatter,
      D = t.prototype.symbols,
      u = A.extend,
      w = A.fireEvent,
      q = A.isNumber,
      E = A.merge,
      g = A.pick;
    t = function (e) {
      function d() {
        var c = null !== e && e.apply(this, arguments) || this;
        c.colorAxis = void 0;
        c.data = void 0;
        c.options = void 0;
        c.points = void 0;
        c.valueMax = NaN;
        c.valueMin = NaN;
        return c;
      }
      r(d, e);
      d.prototype.drawPoints = function () {
        var c = this;
        if ((this.options.marker || {}).enabled || this._hasPointMarkers) m.prototype.drawPoints.call(this), this.points.forEach(function (a) {
          a.graphic && (a.graphic[c.chart.styledMode ? "css" : "animate"](c.colorAttribs(a)), c.options.borderRadius && a.graphic.attr({
            r: c.options.borderRadius
          }), (a.shapeArgs || {}).r = c.options.borderRadius, (a.shapeArgs || {}).d = a.graphic.pathArray, null === a.value && a.graphic.addClass("highcharts-null-point"));
        });
      };
      d.prototype.getExtremes = function () {
        var c = m.prototype.getExtremes.call(this, this.valueData),
          a = c.dataMin;
        c = c.dataMax;
        q(a) && (this.valueMin = a);
        q(c) && (this.valueMax = c);
        return m.prototype.getExtremes.call(this);
      };
      d.prototype.getValidPoints = function (c, a) {
        return m.prototype.getValidPoints.call(this, c, a, !0);
      };
      d.prototype.hasData = function () {
        return !!this.processedXData.length;
      };
      d.prototype.init = function () {
        m.prototype.init.apply(this, arguments);
        var c = this.options;
        c.pointRange = g(c.pointRange, c.colsize || 1);
        this.yAxis.axisPointRange = c.rowsize || 1;
        D.ellipse = D.circle;
      };
      d.prototype.markerAttribs = function (c, a) {
        var b = c.marker || {},
          d = this.options.marker || {},
          e = c.shapeArgs || {},
          f = {};
        if (c.hasImage) return {
          x: c.plotX,
          y: c.plotY
        };
        if (a) {
          var g = d.states[a] || {};
          var h = b.states && b.states[a] || {};
          [["width", "x"], ["height", "y"]].forEach(function (a) {
            f[a[0]] = (h[a[0]] || g[a[0]] || e[a[0]]) + (h[a[0] + "Plus"] || g[a[0] + "Plus"] || 0);
            f[a[1]] = e[a[1]] + (e[a[0]] - f[a[0]]) / 2;
          });
        }
        return a ? f : e;
      };
      d.prototype.pointAttribs = function (c, a) {
        var d = m.prototype.pointAttribs.call(this, c, a),
          e = this.options || {},
          f = this.chart.options.plotOptions || {},
          g = f.series || {},
          h = f.heatmap || {};
        f = c && c.options.borderColor || e.borderColor || h.borderColor || g.borderColor;
        g = c && c.options.borderWidth || e.borderWidth || h.borderWidth || g.borderWidth || d["stroke-width"];
        d.stroke = c && c.marker && c.marker.lineColor || e.marker && e.marker.lineColor || f || this.color;
        d["stroke-width"] = g;
        a && (c = E(e.states[a], e.marker && e.marker.states[a], c && c.options.states && c.options.states[a] || {}), a = c.brightness, d.fill = c.color || b.parse(d.fill).brighten(a || 0).get(), d.stroke = c.lineColor);
        return d;
      };
      d.prototype.setClip = function (b) {
        var a = this.chart;
        m.prototype.setClip.apply(this, arguments);
        (!1 !== this.options.clip || b) && this.markerGroup.clip((b || this.clipBox) && this.sharedClipKey ? a.sharedClips[this.sharedClipKey] : a.clipRect);
      };
      d.prototype.translate = function () {
        var b = this.options,
          a = b.marker && b.marker.symbol || "rect",
          d = D[a] ? a : "rect",
          e = -1 !== ["circle", "square"].indexOf(d);
        this.generatePoints();
        this.points.forEach(function (b) {
          var c = b.getCellAttributes(),
            f = {};
          f.x = Math.min(c.x1, c.x2);
          f.y = Math.min(c.y1, c.y2);
          f.width = Math.max(Math.abs(c.x2 - c.x1), 0);
          f.height = Math.max(Math.abs(c.y2 - c.y1), 0);
          var g = b.hasImage = 0 === (b.marker && b.marker.symbol || a || "").indexOf("url");
          if (e) {
            var h = Math.abs(f.width - f.height);
            f.x = Math.min(c.x1, c.x2) + (f.width < f.height ? 0 : h / 2);
            f.y = Math.min(c.y1, c.y2) + (f.width < f.height ? h / 2 : 0);
            f.width = f.height = Math.min(f.width, f.height);
          }
          h = {
            plotX: (c.x1 + c.x2) / 2,
            plotY: (c.y1 + c.y2) / 2,
            clientX: (c.x1 + c.x2) / 2,
            shapeType: "path",
            shapeArgs: E(!0, f, {
              d: D[d](f.x, f.y, f.width, f.height)
            })
          };
          g && (b.marker = {
            width: f.width,
            height: f.height
          });
          u(b, h);
        });
        w(this, "afterTranslate");
      };
      d.defaultOptions = E(z.defaultOptions, {
        animation: !1,
        borderRadius: 0,
        borderWidth: 0,
        nullColor: "#f7f7f7",
        dataLabels: {
          formatter: function () {
            var b = this.series.chart.numberFormatter,
              a = this.point.value;
            return q(a) ? b(a, -1) : "";
          },
          inside: !0,
          verticalAlign: "middle",
          crop: !1,
          overflow: !1,
          padding: 0
        },
        marker: {
          symbol: "rect",
          radius: 0,
          lineColor: void 0,
          states: {
            hover: {
              lineWidthPlus: 0
            },
            select: {}
          }
        },
        clip: !0,
        pointRange: null,
        tooltip: {
          pointFormat: "{point.x}, {point.y}: {point.value}<br/>"
        },
        states: {
          hover: {
            halo: !1,
            brightness: .2
          }
        }
      });
      return d;
    }(z);
    u(t.prototype, {
      alignDataLabel: v.prototype.alignDataLabel,
      axisTypes: h.SeriesMixin.axisTypes,
      colorAttribs: h.SeriesMixin.colorAttribs,
      colorKey: h.SeriesMixin.colorKey,
      directTouch: !0,
      drawLegendSymbol: n.drawRectangle,
      getExtremesFromAll: !0,
      getSymbol: m.prototype.getSymbol,
      parallelArrays: h.SeriesMixin.parallelArrays,
      pointArrayMap: ["y", "value"],
      pointClass: x,
      trackerGroups: h.SeriesMixin.trackerGroups
    });
    y.registerSeriesType("heatmap", t);
    "";
    "";
    return t;
  });
  x(b, "masters/modules/heatmap.src.js", [b["Core/Globals.js"], b["Core/Axis/Color/ColorAxis.js"]], function (b, h) {
    b.ColorAxis = h;
    h.compose(b.Chart, b.Fx, b.Legend, b.Series);
  });
});
