import { createAction, createActionGroup, props } from '@ngrx/store';
import { OrganizationSetting, OrganizationSettingKind } from '../models/settings/setting.model';
import { v4 as UUID } from 'uuid';

export const loadOrganizationSettings = createAction(
  '[Organization settings] Load all',
  props<{ settings: Record<string, OrganizationSetting> }>(),
);
export const clearOrganizationSettings = createAction('[Organization settings] Clear all');

export const labelActions = createSettingsActions('Labels', 'label');
export const bioRegisterConfigurationActions = createSettingsActions('Bioregister', 'bioregister');
export const lumaConfigurationActions = createSettingsActions('Luma', 'luma');
export const nameSchemeActions = createSettingsActions('Naming Schemes', 'file-naming-scheme');

export type OrgSettingsActionGroup =
  | typeof labelActions
  | typeof nameSchemeActions
  | typeof bioRegisterConfigurationActions
  | typeof lumaConfigurationActions;

function createSettingsActions(
  actionTypePrefix: 'Labels' | 'Naming Schemes' | 'Bioregister' | 'Luma',
  kind: OrganizationSettingKind,
) {
  return createActionGroup({
    source: actionTypePrefix,
    events: {
      Create: (setting: Omit<OrganizationSetting, 'id' | 'kind' | 'created'>) => ({
        setting: {
          ...setting,
          id: UUID(),
          kind,
        },
      }),
      Update: props<{ settingToUpdate: OrganizationSetting }>(),
      'Update success': props<{ updatedSetting: OrganizationSetting }>(),
      'Update fail': props<{ id: string }>(),
      'Create success': props<{ oldId: string; newId: string }>(),
      'Create fail': props<{ failedSetting: OrganizationSetting }>(),
      Remove: props<{ id: string }>(),
      'Remove success': props<{ id: string }>(),
      'Remove fail': props<{ id: string }>(),
    },
  });
}
