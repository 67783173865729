import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'bx-page-message',
  templateUrl: './page-message.component.html',
  standalone: true,
})
export class PageMessageComponent {
  @HostBinding('class') readonly hostClass = [
    'h-100',
    'd-flex',
    'flex-column',
    'justify-content-center',
    'align-items-center',
    'text-center',
    'text-muted',
    'overflow-hidden',
  ];
}
