import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CleanUp } from 'src/app/shared/cleanup';
import { mapObjectValues } from 'src/app/shared/utils/object';
import { selectOrganizationID } from '../../auth/auth.selectors';
import { AppState } from '../../core.store';
import { labelActions } from '../../organization-settings/organization-settings.actions';
import {
  selectLabelByNameAndColor,
  selectOrganizationSettingsByKind,
} from '../../organization-settings/organization-settings.selectors';
import { Label, orgSettingToLabel } from '../label.model';

export type PickableLabel = Label & { selected: boolean; displayed: boolean };
export type LabelPickerState = { search: string; labels: { [id: string]: PickableLabel } };
const initialState: LabelPickerState = { search: '', labels: {} };

/**
 * TODO: replace with @ngrx/component-store when we upgrade to ngrx v14
 *
 * I wanted to use @ngrx/component-store for this component, but unfortunately
 * I encountered a bug that is fixed in v14: https://github.com/ngrx/platform/pull/3492
 * We are stuck on v13 un until we upgrade to Angular 14. I really like the
 * ComponentStore abstraction, so instead of ripping it out, I wrote this barebones
 * implementation that can be swapped out for the real thing when we upgrade.
 */
abstract class ComponentStore<T> extends CleanUp {
  readonly state$: BehaviorSubject<T>;
  protected readonly destroy$ = this.ngUnsubscribe;

  constructor(initialState: T) {
    super();
    this.state$ = new BehaviorSubject<T>(initialState);
  }

  protected select<U>(selector: (state: T) => U): Observable<U> {
    return this.state$.pipe(map((state) => selector(state)));
  }

  protected updater<U>(updateFn: (state: T, params?: U) => T): (params?: U) => void {
    return (params) =>
      this.state$.pipe(take(1)).subscribe((state) => this.state$.next(updateFn(state, params)));
  }
}

/**
 * Manages state for the LabelPickerComponent, allowing labels from the root
 * store to be selected, deselected, and filtered with a search.
 */
@Injectable()
export class LabelPickerComponentStore extends ComponentStore<LabelPickerState> {
  constructor(private readonly store: Store<AppState>) {
    super(initialState);
    // Link the root store to this component store
    this.store
      .pipe(
        select(selectOrganizationSettingsByKind('label')),
        map((settings) => settings.map((setting) => orgSettingToLabel(setting))),
        tap((storeLabels) => this.replaceLabels(storeLabels)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * Dispatches a createLabel action on the root store, then sets it as selected
   * once it has been created.
   * @param label label to create
   */
  createLabel({ name, color }: Omit<Label, 'id'>): void {
    this.store
      .pipe(
        // Create label
        select(selectOrganizationID),
        map((organizationID) =>
          labelActions.create({ organizationID, name, data: { color, created: new Date() } }),
        ),
        tap((action) => this.store.dispatch(action)),
        // Wait for label to be added to the component store
        switchMap(() => this.store.select(selectLabelByNameAndColor(name, color))),
        first((label) => !!label),
        // Set it as selected
        tap(({ id }) => this.setSelected({ ids: [id], selected: true })),
      )
      .subscribe();
  }

  /** Selector for an array of all labels sorted alphabetically */
  readonly labels$ = this.select((state) =>
    Object.values(state.labels).sort((a, b) => a.name.localeCompare(b.name)),
  );

  /** Selector for an array of labels that have `displayed` set to true. */
  readonly displayedLabels$ = this.labels$.pipe(
    map((labels) => labels.filter((label) => label.displayed)),
    shareReplay(1),
    takeUntil(this.destroy$),
  );

  /** Selector for an array of labels that have `selected` set to true. */
  readonly selectedLabels$ = this.labels$.pipe(
    map((labels) => labels.filter((label) => label.selected)),
    shareReplay(1),
    takeUntil(this.destroy$),
  );

  /**
   * Updates the `displayed` property for all labels in the store based on
   * whether the `search` string matches the label's name.
   *
   * @param search the search string
   */
  readonly filterDisplayed = this.updater((state, searchTerm: string) => {
    const search = (searchTerm ?? '').trim().toLowerCase();
    return {
      search,
      labels: mapObjectValues(state.labels, (label) => ({
        ...label,
        displayed: this.matchesSearch(label, search),
      })),
    };
  });

  /**
   * Sets the `selected` property for the specified labels.
   *
   * @param ids the ids of labels to update
   * @param selected the new value of the selected property
   */
  readonly setSelected = this.updater(
    (state, { ids, selected }: { ids: string[]; selected: boolean }) => ({
      ...state,
      labels: {
        ...state.labels,
        ...Object.fromEntries(ids.map((id) => [id, { ...state.labels[id], selected }])),
      },
    }),
  );

  /**
   * Replaces the labels in the component store with the latest labels from the
   * root store. The selected state for labels in the component store is
   * persisted. Labels that were removed from the root store are removed from
   * the component store.
   *
   * @param storeLabels the new set of labels
   */
  private readonly replaceLabels = this.updater((state, storeLabels: Label[]) => {
    // The state should only include labels that are in the root store
    const labels = Object.fromEntries(
      storeLabels.map((storeLabel) => {
        // Copy selected state if label is already in store
        const selected = state.labels[storeLabel.id]?.selected ?? false;
        // Recalculate displayed state because label could have been renamed
        const displayed = this.matchesSearch(storeLabel, state.search);
        return [storeLabel.id, { ...storeLabel, selected, displayed }];
      }),
    );
    return { ...state, labels };
  });

  private matchesSearch(label: { name: string }, searchTerm: string) {
    return label.name.toLowerCase().includes(searchTerm);
  }
}
