import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { GridApi, ICellRendererParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, take } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { compareStrings } from 'src/app/shared/utils/object';
import { CleanUp } from '../../../shared/cleanup';
import { AppState } from '../../core.store';
import { replaceCellValueEdit } from '../../document-table-edits/document-table-edits.actions';
import { DocumentTableCell } from '../../document-table-edits/document-table-edits.reducer';
import { selectEditedStateForListSetCell } from '../../document-table-edits/document-table-edits.selectors';
import { DocumentTableViewerService } from '../../document-table-service/document-table-viewer.service';
import { FolderService } from '../../folders/folder.service';
import { selectLabels } from '../../organization-settings/organization-settings.selectors';
import { Label, orgSettingToLabel } from '../label.model';
import { AsyncPipe } from '@angular/common';
import { LabelSingleSelectComponent } from '../label-picker/label-single-select/label-single-select.component';
import { LabelBadgeComponent } from '../label-badge/label-badge.component';

@Component({
  selector: 'bx-label-cell',
  templateUrl: './label-renderer-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbPopover, LabelSingleSelectComponent, LabelBadgeComponent, AsyncPipe],
})
export class LabelRendererV2Component
  extends CleanUp
  implements ICellRendererAngularComp, OnDestroy
{
  @HostBinding('class') readonly hostClass = 'd-flex';
  @ViewChild('labelPickerPopover') popover: NgbPopover;
  /** Label names initially populated by ag params, then overridden by the edit store */
  private readonly labelNames$ = new ReplaySubject<string[]>(1);
  /** Label data derived from labelNames$, used in the template for displaying labels */
  readonly labels$ = combineLatest([this.labelNames$, this.store.select(selectLabels)]).pipe(
    map(([labelNames, labelSettings]) =>
      labelNames
        .map((labelName) => {
          const setting = labelSettings.find((label) => labelName === label.name);
          if (!setting) {
            console.error('No organization setting found for label ' + labelName);
            return null;
          }
          return orgSettingToLabel(setting);
        })
        .filter((label) => !!label)
        .sort((a, b) => a.name.localeCompare(b.name)),
    ),
    takeUntil(this.ngUnsubscribe),
  );
  /** For the label picker's initialSelection input */
  readonly labelIDs$: Observable<string[]> = this.labels$.pipe(
    map((labels) => labels.map((label) => label.id)),
    takeUntil(this.ngUnsubscribe),
  );
  /** If the Label Renderer has write access to the table */
  readonly canWrite$: Observable<boolean>;
  /** Cell data, populated in agInit */
  private readonly cell$ = new ReplaySubject<DocumentTableCell>();
  /**
   * Overrides the currently selected folder's write permissions if a readonly
   * state was given into the 'cellRendererParams' for this renderer.
   */
  private readonly readonlyOverride$ = new BehaviorSubject<boolean>(false);
  /** Callback used for scroll event listener. */
  private readonly closePopover = () => this.popover?.close();
  /** AG Grid API. For listening to scroll events. */
  private gridApi: GridApi;

  constructor(
    private readonly store: Store<AppState>,
    private readonly folderService: FolderService,
    private readonly documentTableViewerService: DocumentTableViewerService,
  ) {
    super();
    this.canWrite$ = combineLatest([
      this.readonlyOverride$,
      this.folderService.selectedFolderIsWriteable$,
    ]).pipe(
      map(([readonlyOverride, isWriteable]) => {
        if (readonlyOverride) {
          return false;
        } else {
          return isWriteable;
        }
      }),
    );
  }

  agInit(params: ICellRendererParams): void {
    this.gridApi = params.api;
    this.readonlyOverride$.next(
      params.colDef.cellRendererParams?.readonly || params.context?.previewMode,
    );
    const row = params.data?.row_number ?? params.node.data?.row_number;

    // Populate cell$
    this.documentTableViewerService
      .getSelectedTable()
      .pipe(
        take(1),
        distinctUntilChanged(compareStrings(({ documentID, name }) => documentID + name)),
        map(({ documentID, name }) => ({
          documentID,
          tableName: name,
          row,
          column: 'Labels',
        })),
      )
      .subscribe((cell) => this.cell$.next(cell));

    // Populate labelNames$
    const initialLabelNames = (params.value ?? []).sort() as string[];
    this.cell$
      .pipe(
        take(1),
        switchMap(({ documentID, tableName, row, column }) =>
          this.store.select(
            selectEditedStateForListSetCell(documentID, tableName, row, column, initialLabelNames),
          ),
        ),
        distinctUntilChanged(
          (prev, current) => prev.length === current.length && prev.join() === current.join(),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((editedCellValue) => this.labelNames$.next(editedCellValue));
  }

  refresh(params: ICellRendererParams): boolean {
    if (params.colDef.cellRendererParams?.readonly) {
      this.readonlyOverride$.next(params.colDef.cellRendererParams?.readonly);
    }
    return true;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.cell$.complete();
    this.readonlyOverride$.complete();
    this.labelNames$.complete();
    if (this.gridApi != null) {
      this.gridApi.removeEventListener('bodyScroll', this.closePopover);
    }
  }

  /**
   * Adds the specified label to the cell's list of labels.
   * @param label to add
   */
  addLabel(label: Label) {
    this.labelNames$
      .pipe(
        map((labelNames) => [...labelNames, label.name].sort()),
        take(1),
        // Close popover after the label is selected
        tap(() => this.popover.close()),
      )
      .subscribe((labelNames) => this.dispatchCellValueEdit(labelNames));
  }

  /**
   * Removes the specified label from the cell's list of labels.
   * @param label to remove
   */
  removeLabel(label: Label) {
    this.labelNames$
      .pipe(
        map((labelNames) => labelNames.filter((labelName) => labelName !== label.name)),
        take(1),
      )
      .subscribe((labelNames) => this.dispatchCellValueEdit(labelNames));
  }

  onPopoverShown() {
    this.gridApi.addEventListener('bodyScroll', this.closePopover);
  }

  onPopoverHidden() {
    this.gridApi.removeEventListener('bodyScroll', this.closePopover);
  }

  private dispatchCellValueEdit(value: string[]) {
    this.cell$
      .pipe(take(1))
      .subscribe((cell) => this.store.dispatch(replaceCellValueEdit({ ...cell, value })));
  }
}
