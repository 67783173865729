import * as Highcharts from 'highcharts';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { observeResize } from '../../../../bx-operators/observe-resize';
import HEATMAP from 'highcharts/modules/heatmap';
import SANKEY from 'highcharts/modules/sankey';
import EXPORT from 'highcharts/modules/exporting';
import OFFLINE_EXPORT from 'highcharts/modules/offline-exporting';
import HC_more from 'highcharts/highcharts-more';
import { HighchartsChartModule } from 'highcharts-angular';

HEATMAP(Highcharts);
SANKEY(Highcharts);
EXPORT(Highcharts);
OFFLINE_EXPORT(Highcharts);
HC_more(Highcharts);

@Component({
  selector: 'bx-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HighchartsChartModule],
})
export class ChartComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class') readonly hostClass = 'flex-grow-1 flex-shrink-1';
  @Input() chartOptions: Highcharts.ChartOptions = {};
  @Input() title: Highcharts.TitleOptions = {};
  @Input() subtitle: Highcharts.SubtitleOptions = {};
  @Input() xAxis: Highcharts.XAxisOptions = {};
  @Input() yAxis: Highcharts.YAxisOptions = {};
  @Input() tooltip: Highcharts.TooltipOptions = {};
  @Input() legend: Highcharts.LegendOptions = { enabled: false };
  @Input() plotOptions: Highcharts.PlotOptions = {};
  @Input() series: Highcharts.SeriesOptionsType[] = [];
  @Input() colorAxis?: Highcharts.ColorAxisOptions;
  @Input() colors?: Highcharts.ColorString[];
  @Input() message?: string;

  @Output() chartLoaded: EventEmitter<Highcharts.Chart> = new EventEmitter();

  Highcharts: typeof Highcharts = Highcharts;
  options: Highcharts.Options = {};
  exporting: Highcharts.ExportingOptions = {
    fallbackToExportServer: false,
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  };
  chart: Highcharts.Chart;

  resizeSubscription = new Subscription();

  defaultColors: string[] = [
    '#7cb5ec',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ];

  // Redraw if the browser window resizes in anyway.
  @HostListener('window:resize', ['$event.target'])
  resize() {
    if (this.chart) {
      this.chart.reflow();
    }
  }

  onChartLoaded(chart: Highcharts.Chart) {
    this.chart = chart;
    this.chartLoaded.emit(chart);

    this.resizeSubscription.unsubscribe();

    this.resizeSubscription = observeResize(this.chart.container.parentElement).subscribe(() =>
      this.resize(),
    );
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }

  downloadImage(exportingOptions?: Highcharts.ExportingOptions, chartOptions?: Highcharts.Options) {
    if (this.chart) {
      this.chart.exportChartLocal(exportingOptions, chartOptions);
    }
  }

  applyColor() {
    const target = this.chart?.options ?? this.options;
    target.colors = this.colors?.length > 0 ? this.colors : this.defaultColors;
  }

  ngOnInit() {
    let text: Highcharts.SVGElement;
    this.options = {
      chart: {
        ...this.chartOptions,
        events: {
          render: () => {
            if (this.chart) {
              if (text) {
                text.destroy();
              }
              if (this.message) {
                const chartHeight = this.chart.chartHeight;
                const chartWidth = this.chart.chartWidth;
                text = this.chart.renderer
                  .text(this.message, chartWidth / 2, chartHeight / 2)
                  .css({
                    fontSize: '16px',
                  })
                  .add();
                text.toFront().translate(-(text.getBBox().width / 2), 0);
              }
            }
          },
        },
      },
      title: this.title,
      subtitle: this.subtitle,
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      colorAxis: this.colorAxis,
      tooltip: this.tooltip,
      legend: this.legend,
      plotOptions: this.plotOptions,
      series: this.series,
      exporting: this.exporting,
      credits: {
        enabled: false,
      },
    };
    this.applyColor();
  }

  ngOnChanges({
    chartOptions,
    title,
    subtitle,
    xAxis,
    yAxis,
    tooltip,
    legend,
    plotOptions,
    series,
    colorAxis,
    colors,
  }: SimpleChanges): void {
    if (!this.chart) {
      return;
    }

    if (title || subtitle) {
      this.chart.setTitle(this.title, this.subtitle, false);
    }

    if (xAxis) {
      while (this.chart.xAxis.length > 0) {
        this.chart.xAxis[0].remove(false);
      }
      this.chart.addAxis(xAxis.currentValue, true, false);
    }

    if (yAxis) {
      while (this.chart.yAxis.length > 0) {
        this.chart.yAxis[0].remove(false);
      }
      this.chart.addAxis(yAxis.currentValue, false, false);
    }

    if (tooltip) {
      this.chart.tooltip.update(tooltip.currentValue);
    }

    if (legend) {
      this.chart.legend.update(legend.currentValue, false);
    }

    if (plotOptions) {
      this.chart.update({ plotOptions: plotOptions.currentValue }, false);
    }

    if (series) {
      while (this.chart.series.length > 0) {
        this.chart.series[0].remove(false);
      }
      const seriesValues = Array.isArray(series.currentValue) ? series.currentValue : [];
      for (const s of seriesValues) {
        this.chart.addSeries(s, false);
      }
    }

    if (colors) {
      this.applyColor();
    }

    this.chart.redraw();
  }
}
