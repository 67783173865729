import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ColorString, PlotOptions, SeriesColumnOptions } from 'highcharts';
import { GraphUtilService } from '../graph-util.service';
import { DownloadBlobService } from '../../../core/download-blob.service';
import { naturalSortCompare } from '../../../shared/sort.util';
import { stackedColumnTooltipFormatter } from '../tooltips/stacked-column-tooltip';
import { BaseChartComponent } from '../abstract-column-chart/base-chart.component';
import { DownloadTableStackedColumnOptions } from '../exportable-chart';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'bx-stacked-column-chart',
  templateUrl: './stacked-column-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ChartComponent],
})
export class StackedColumnChartComponent
  extends BaseChartComponent<SeriesColumnOptions>
  implements OnChanges
{
  @Input() sortColumns = false;
  @Input() colors?: ColorString[];

  plotOptions: PlotOptions = {
    column: {
      groupPadding: 0,
      pointPadding: 0.1,
      borderWidth: 0.1,
      dataLabels: {
        verticalAlign: 'top',
        formatter: function (): string {
          // @ts-ignore
          // The height and width values are only valid for the default font. Can't seem to get them dynamically.
          if (this.point.shapeArgs.height >= 22 && this.point.shapeArgs.width >= 14) {
            // @ts-ignore
            return this.series.name;
          }
        },
      },
    },
  };

  tooltipOptions = {
    formatter: stackedColumnTooltipFormatter,
  };

  downloadTable({ documentName, headerText }: DownloadTableStackedColumnOptions): void {
    const name = documentName
      ? `${this.titleOptions.text} (${documentName})`
      : this.titleOptions.text;
    const data = this.getTableData(headerText);
    DownloadBlobService.download(`${name}.csv`, data);
  }

  prepareSeries(data: SeriesColumnOptions[]): SeriesColumnOptions[] {
    return this.sortColumns ? this.reorderColumnSegments(data) : data;
  }

  reorderColumnSegments(data: SeriesColumnOptions[]): SeriesColumnOptions[] {
    // Required in order for each column to be ordered from high to low rather than have a consistent ordering across all columns.
    const reorderedSeries: SeriesColumnOptions[] = [];
    data.forEach((series) => {
      series.data.forEach((point) => {
        const newSeries = { ...series };
        newSeries.data = [point];

        // eliminate duplicates in the legend
        newSeries.showInLegend = !reorderedSeries.some((s) => s.name === newSeries.name);

        reorderedSeries.push(newSeries);
      });
    });

    reorderedSeries.sort(
      (a, b) => GraphUtilService.getPointY(b.data[0]) - GraphUtilService.getPointY(a.data[0]),
    );
    return reorderedSeries;
  }

  ngOnChanges({ yAxisTitle }: SimpleChanges) {
    if (yAxisTitle) {
      const isPercentageData = this.yAxisOptions.title.text.includes('%');
      if (isPercentageData) {
        this.yAxisRange = { min: 0, ceiling: 100 };
      } else {
        this.yAxisRange = undefined;
      }
    }
  }

  private getTableData(headerText: string) {
    const xAxisCategories = this.series
      .map((series) => series.data.map((point) => GraphUtilService.getPointX(point)))
      .reduce((prev, curr) => prev.concat(curr), [])
      .filter((value, index, arr) => arr.findIndex((x) => value === x) === index)
      .map((value) => String(value))
      .sort(naturalSortCompare);
    const headers = [headerText, ...xAxisCategories].join() + '\n';

    const body = this.series.map((series) => {
      const data: string[] = Array(xAxisCategories.length).fill('0');
      series.data.forEach((point) => {
        const index = xAxisCategories.indexOf(String(GraphUtilService.getPointX(point)));
        return (data[index] = String(GraphUtilService.getPointY(point)));
      });
      return [series.name, ...data];
    });

    return headers + body.join('\n');
  }
}
