import {
  Tooltip,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject,
} from 'highcharts';
import { roundForGraph } from '../../../shared/utils/rounding';

export const stackedColumnTooltipFormatter: TooltipFormatterCallbackFunction = function (
  tooltip: Tooltip,
) {
  const { point, series } = this as TooltipFormatterContextObject;
  const percent = tooltip.chart.options.plotOptions.column.stacking === 'percent';
  const header = `<b>${series.xAxis.options.title.text} ${point.options.name || point.options.x}${
    percent ? '' : ` (Total: ${roundForGraph(point.total)})`
  }</b> (3 s.f.)</br>`;

  const pointsInStack = series.chart.series
    // TODO: remove @ts-ignore when we update highcharts typing
    // @ts-ignore
    .filter((s) => s.xData.includes(point.category))
    .map((s) => s.points.find((p) => p.category === point.category));

  const currentSectionName = series.name;
  const body = pointsInStack
    .map((p) => {
      const content = percent ? `${roundForGraph(p.percentage)}%` : `${roundForGraph(p.y)}`;
      return currentSectionName === p.series.name
        ? `<strong>${p.series.name}: ${content}</strong>`
        : `${p.series.name}: ${content}`;
    })
    .join(`</br>`);
  return header + body;
};
