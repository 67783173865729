import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component } from '@angular/core';
import { IHeaderParams } from '@ag-grid-community/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import {
  BioregisterService,
  BioregisterIDColumns,
} from '../pipeline-dialogs/register-sequences/bioregister.service';
import { AsyncPipe } from '@angular/common';

type ResourceType = 'clone' | 'complex';

/**
 * For tables: Show links to Bioregister resource with the specified ID
 */
@Component({
  selector: 'bx-bioregister-link',
  templateUrl: './bioregister-link.component.html',
  standalone: true,
  imports: [AsyncPipe],
})
export class BioregisterLinkComponent implements IHeaderAngularComp {
  bioregisterID: string;
  bioregisterLink$: Observable<string>;
  resourceType: ResourceType;

  private RESOURCE_PATH: { [k in ResourceType]: string } = {
    clone: 'bioregister/clone/show',
    complex: 'bioregister/complex/show',
  };

  constructor(private bioregisterService: BioregisterService) {}

  agInit(params: IHeaderParams): void {
    const data = (params as any).data as CellData;
    const columnID = params.column.getColId();
    if (data && this.isValidResourceColumn(columnID)) {
      this.resourceType =
        columnID === BioregisterIDColumns.COMPLEX_BIOREGISTER_ID_COLUMN ? 'complex' : 'clone';
      this.bioregisterID = data[columnID];
      this.bioregisterLink$ = this.bioregisterService
        .getBioregisterUrl()
        .pipe(
          map((url) => `${url}/${this.RESOURCE_PATH[this.resourceType]}/${this.bioregisterID}`),
        );
    }
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }

  private isValidResourceColumn(columnID: string): boolean {
    return BioregisterIDColumns.ALL_COLUMNS.includes(columnID);
  }
}

type CellData = Partial<{ [key in (typeof BioregisterIDColumns.ALL_COLUMNS)[number]]: string }>;
