import { BulkRowUpdate, TableStatusResponse } from '@geneious/nucleus-api-client';
import { createAction, props } from '@ngrx/store';
import { BulkSelectionWithSqlQuery } from '../label/bulk-add-labels/bulk-add-labels.component';
import {
  AddValueEdit,
  DocumentTableCell,
  DocumentTableID,
  Edit,
  ReplaceValueEdit,
  StoredBulkEdit,
} from './document-table-edits.reducer';

export type DocumentTableCellReplaceValueEdit<T = unknown> = DocumentTableCell &
  ReplaceValueEdit<T>;

export type DocumentTableBulkEdit<T = unknown> = DocumentTableID & {
  /** The edit to apply to affected cells */
  edit: Edit<T>;
  /** The column to use for all rows in the selection */
  column: string;
  /**
   * The selection containing rowNumbers, selectAll, and a SQL query. This needs
   * to be converted to Lucene query string format for the bulk row update API.
   */
  selection: BulkSelectionWithSqlQuery;
};

export type DocumentTableBulkEditWithRequest<T = unknown> = DocumentTableID &
  Omit<StoredBulkEdit<T>, 'status' | 'editTimestamp'> & { request: BulkRowUpdate };

/**
 * Emitted when the user edits a cell.
 */
export const replaceCellValueEdit = createAction(
  '[Document Table Edits] Replace Cell Value / User Edit',
  props<DocumentTableCellReplaceValueEdit>(),
);

/**
 * Emitted when the replaceCellValue API request is sent.
 */
export const replaceCellValuePostRequest = createAction(
  '[Document Table Edits] Replace Cell Value / API / POST Request',
  props<DocumentTableCellReplaceValueEdit & { attempt: number }>(),
);

/**
 * Emitted when Nucleus responds to the replaceCellValue API request with OK.
 * This means the request has been accepted, but the edit is not been applied.
 */
export const replaceCellValuePostRequestAcked = createAction(
  '[Document Table Edits] Replace Cell Value / API / Acknowledged',
  props<DocumentTableCellReplaceValueEdit & { attempt: number; response: TableStatusResponse }>(),
);

/**
 * Emitted when Nucleus responds to the replaceCellValue API request with an error.
 */
export const replaceCellValuePostRequestError = createAction(
  '[Document Table Edits] Replace Cell Value / API / Error',
  props<DocumentTableCellReplaceValueEdit & { attempt: number; error: unknown }>(),
);

/**
 * Emitted when the DocumentTable activity stream sends a notification that the
 * cell has been replaced.
 */
export const replaceCellValueEditSuccess = createAction(
  '[Document Table Edits] Replace Cell Value / Success',
  props<DocumentTableCellReplaceValueEdit>(),
);

/**
 * Emitted when the maximum number of request retries has been exceeded.
 */
export const replaceCellValueEditFailed = createAction(
  '[Document Table Edits] Replace Cell Value / Failed',
  props<DocumentTableCellReplaceValueEdit & { error: unknown }>(),
);

/**
 * Emitted for the previous document table when a new document table is selected.
 */
export const documentTableFocusLost = createAction(
  '[Document Table Edits] Document Table Focus Lost',
  props<DocumentTableID>(),
);

/**
 * Emitted when the user confirms a bulk row update.
 */
export const bulkRowUpdateEdit = createAction(
  '[Document Table Edits] Bulk Row Update / User Edit',
  props<DocumentTableBulkEdit>(),
);

/**
 * Emitted when the bulkRowUpdate API request is sent. Contains the data for
 * affected cell state.
 */
export const bulkRowUpdatePostRequest = createAction(
  '[Document Table Edits] Bulk Row Update / API / POST Request',
  props<DocumentTableBulkEditWithRequest & { attempt: number }>(),
);

/**
 * Emitted when Nucleus responds to the bulkRowUpdate API request with OK.
 * This means the request has been accepted, but the edit is not been applied.
 */
export const bulkRowUpdatePostRequestAcked = createAction(
  '[Document Table Edits] Bulk Row Update / API / Acknowledged',
  props<DocumentTableBulkEditWithRequest & { attempt: number; response: TableStatusResponse }>(),
);

/**
 * Emitted when Nucleus responds to the bulkRowUpdate API request with an error.
 */
export const bulkRowUpdatePostRequestError = createAction(
  '[Document Table Edits] Bulk Row Update / API / Error',
  props<DocumentTableBulkEditWithRequest & { attempt: number; error: unknown }>(),
);

/**
 * Emitted when the Nucleus sends a document activity event that says that the
 * update has finished applying.
 */
export const bulkRowUpdateEditSuccess = createAction(
  '[Document Table Edits] Bulk Row Update / Success',
  props<DocumentTableID>(),
);

/**
 * Emitted when the maximum number of request retries has been exceeded.
 */
export const bulkRowUpdateEditFailed = createAction(
  '[Document Table Edits] Bulk Row Update / Failed',
  props<DocumentTableBulkEditWithRequest & { error: unknown }>(),
);
