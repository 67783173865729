import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges } from '@angular/core';
import { DownloadBlobService } from '../../../core/download-blob.service';
import { GraphUtilService } from '../graph-util.service';
import { SeriesColumnOptions, TooltipOptions } from 'highcharts';
import { columnTooltipFormatter } from '../tooltips/column-tooltip';
import { BaseChartComponent } from '../abstract-column-chart/base-chart.component';
import { DownloadTableOptions } from '../exportable-chart';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'bx-column-chart',
  templateUrl: '../abstract-column-chart/base-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ChartComponent],
})
export class ColumnChartComponent
  extends BaseChartComponent<SeriesColumnOptions>
  implements OnChanges
{
  plotOptions = {
    series: {
      cropThreshold: 300,
    },
  };

  tooltipOptions: TooltipOptions = {
    formatter: columnTooltipFormatter,
  };

  ngOnChanges({ yAxisTitle }: SimpleChanges) {
    if (yAxisTitle) {
      const isPercentageData = this.yAxisOptions.title.text.includes('%');
      if (isPercentageData) {
        this.yAxisRange = { min: 0, ceiling: 100 };
      } else {
        this.yAxisRange = undefined;
      }
    }
  }

  downloadTable({ documentName }: DownloadTableOptions): void {
    const name = documentName
      ? `${this.titleOptions.text} (${documentName})`
      : this.titleOptions.text;
    const headers = `${this.xAxisOptions?.title?.text}, ${this.yAxisOptions?.title?.text}\n`;
    const body = this.series[0].data
      .map((point) => `${GraphUtilService.getPointX(point)}, ${GraphUtilService.getPointY(point)}`)
      .join('\n');
    DownloadBlobService.download(`${name}.csv`, headers + body);
  }
}

export interface IBarChartData {
  label: string;
  yValue: number;
  yValueType?: Y_VALUE_TYPE;
}

export enum Y_VALUE_TYPE {
  COUNT,
  PERCENTAGE,
}
