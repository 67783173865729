import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParamsWithGridService } from '../grid.interfaces';

/**
 * TODO Should this just be a regular ag-grid column `valueGetter` instead?
 *      it's more performant to use `valueGetter` instead of a heavy compiled
 *      Angular component.
 *
 */
@Component({
  selector: 'bx-match-count-renderer',
  templateUrl: './match-count-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MatchCountRendererComponent implements AgRendererComponent {
  value = 0;

  constructor() {}

  agInit(params: ICellRendererParamsWithGridService): void {
    this.refresh(params);
  }

  // Called by AG-GRID when refreshing the cell.
  refresh(params: ICellRendererParamsWithGridService): boolean {
    this.value = (params.getValue() || []).length;

    return true;
  }
}
