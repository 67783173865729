import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { SelectOption } from '../../models/ui/select-option.model';
import { first, map } from 'rxjs/operators';
import { selectBioregisterUrl } from '../../organization-settings/organization-settings.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { selectUserJWT } from '../../auth/auth.selectors';

interface Project {
  id: number;
  name: string;
}

interface Target {
  id: number;
  bioregId: string;
  speciesAndSymbol: string;
}

export class BioregisterIDColumns {
  static HEAVY_SEQ_BIOREGISTER_ID_COLUMN = 'Heavy Sequence ID';
  static LIGHT_SEQ_BIOREGISTER_ID_COLUMN = 'Light Sequence ID';
  static COMPLEX_BIOREGISTER_ID_COLUMN = 'Complex ID';
  static BIOREGISTER_COLUMN_GROUP = 'Dotmatics Bioregister';

  static ALL_COLUMNS = [
    this.HEAVY_SEQ_BIOREGISTER_ID_COLUMN,
    this.LIGHT_SEQ_BIOREGISTER_ID_COLUMN,
    this.COMPLEX_BIOREGISTER_ID_COLUMN,
  ];
}

@Injectable({
  providedIn: 'root',
})
export class BioregisterService {
  private apiURL$: Observable<string>;
  private jwt$: Observable<string>;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.apiURL$ = this.store.select(selectBioregisterUrl).pipe(first());

    this.jwt$ = this.store.select(selectUserJWT).pipe(first());
  }

  getBioregisterUrl(): Observable<string> {
    return this.apiURL$.pipe(map((apiUrl) => new URL(apiUrl).origin));
  }

  getProjectOptions(): Observable<SelectOption<number>[]> {
    return this.get<Project[]>('/project').pipe(
      map((projects) => projects.map((project) => new SelectOption(project.name, project.id))),
    );
  }

  getTargetOptions(): Observable<SelectOption[]> {
    return this.get<Target[]>('/resource/target').pipe(
      map((targets) =>
        targets.map(
          (target) => new SelectOption(this.getTargetDisplayName(target), target.bioregId),
        ),
      ),
    );
  }

  getSpeciesOptions(): Observable<SelectOption[]> {
    const species = [
      'Bovine',
      'Cat',
      'Chinese Hamster',
      'Cynomolgus Monkey',
      'Dog',
      'Drosophila melanogaster',
      'Green Monkey',
      'Horse',
      'Human',
      'Mouse',
      'N/A',
      'Other',
      'Pig',
      'Rabbit',
      'Rat',
      'Rhesus Monkey',
      'Sheep',
      'Zebrafish',
    ];
    const options = species.map((s) => new SelectOption(s, s));
    return of(options);
  }

  getIsotypeOptions(): Observable<SelectOption[]> {
    const isotypes = [
      'IgA',
      'IgA1',
      'IgA2',
      'IgD',
      'IgE',
      'IgG1',
      'IgG2',
      'IgG2a',
      'IgG2b',
      'IgG2c',
      'IgG3',
      'IgG4',
      'IgM',
      'Kappa',
      'Lambda',
      'N/A',
      'Other',
    ];
    const options = isotypes.map((isotype) => new SelectOption(isotype, isotype));
    return of(options);
  }

  private get<T>(url: string): Observable<T> {
    return forkJoin([this.apiURL$, this.jwt$]).pipe(
      switchMap(([apiURL, jwt]) => {
        const headers = new HttpHeaders()
          .set('Authorization', `Bearer ${jwt}`)
          .set('X-Requested-With', 'XMLHttpRequest');
        return this.http.get<T>(`${apiURL}${url}`, { headers });
      }),
    );
  }

  private getTargetDisplayName(target: Target) {
    return `${target.bioregId} (${target.speciesAndSymbol})`;
  }
}
