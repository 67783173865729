import {
  DocumentServiceColumn,
  DocumentServiceTableColumnGroup,
  DocumentServiceTableInfo,
  DocumentServiceTableInfoMap,
} from '../../../../nucleus/services/documentService/types';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { isColDef, isColGroupDef } from '../../folders/models/colDefs';
import { OrganizationSetting } from '../../models/settings/setting.model';
import { JoinedTableHeaderComponent } from '../joined-table-header.component';
import { generateJoinedColumnID } from '../../document-service/document-service.functions';
import { LabelRendererV2Component } from '../../label/label-renderer-v2/label-renderer-v2.component';
import { MatchCountRendererComponent } from '../../../features/grid/match-count-renderer/match-count-renderer.component';
import { BioregisterLinkComponent } from '../../bioregister-link/bioregister-link.component';
import { BioregisterIDColumns } from '../../pipeline-dialogs/register-sequences/bioregister.service';

export const fallBackIndex: Readonly<number> = 3;

export function getAllColumnsWithJoins(
  baseTableName: string,
  tables: DocumentServiceTableInfoMap,
  nameScheme?: OrganizationSetting,
  readonly?: boolean,
): (ColDef | ColGroupDef)[] {
  const originalCols = {
    tableName: baseTableName,
    info: tables[baseTableName],
  };

  const joined = getJoinedTables(tables, baseTableName);

  const columns = setDefaultSortOrder(generateColumnDefs(originalCols, joined, nameScheme));

  ifHasColumn(columns, 'Notes', (columnIndex) => {
    (columns[columnIndex] as ColDef).editable = true;
    (columns[columnIndex] as ColDef).width = 100;
  });

  ifHasColumn(columns, 'Labels', (columnIndex) => {
    columns[columnIndex] = addLabelColDefPropertiesToColDef(columns[columnIndex], readonly);
  });

  ifHasColumn(columns, 'matches', (columnIndex) => {
    columns[columnIndex] = addMatchCountColDefPropertiesToColDef(columns[columnIndex]);
  });

  ifHasColumnGroup(columns, BioregisterIDColumns.BIOREGISTER_COLUMN_GROUP, (columnGroupIndex) => {
    const columnGroup = columns[columnGroupIndex] as ColGroupDef;
    const columnGroupColumns = columnGroup.children;
    BioregisterIDColumns.ALL_COLUMNS.forEach((columnKey) => {
      ifHasColumn(columnGroupColumns, columnKey, (columnIndex) => {
        columnGroupColumns[columnIndex] = addBioregisterIDColDefPropertiesToColDef(
          columnGroupColumns[columnIndex],
        );
      });
    });
  });

  return columns;
}

export function setDefaultSortOrder(input: (ColDef | ColGroupDef)[]): (ColDef | ColGroupDef)[] {
  input = moveColDefAfterNameOrIdField(input, 'matches', fallBackIndex + 2);
  input = moveColDefAfterNameOrIdField(input, 'Notes', fallBackIndex + 1);
  input = moveColDefAfterNameOrIdField(input, 'Labels');
  return input;
}

export function ifHasColumn(
  columns: ColDef[],
  columnKey: string,
  callback: (index: number) => void,
) {
  const labelsColumnIndex = columns.findIndex((col) => (<ColDef>col).field === columnKey);
  if (labelsColumnIndex !== -1) {
    callback(labelsColumnIndex);
  }
}

export function ifHasColumnGroup(
  columns: (ColDef | ColGroupDef)[],
  columnGroupKey: string,
  callback: (index: number) => void,
) {
  const columnGroupIndex = columns.findIndex((col) => {
    return isColGroupDef(col) && col.groupId === columnGroupKey;
  });
  if (columnGroupIndex !== -1) {
    callback(columnGroupIndex);
  }
}

export function addLabelColDefPropertiesToColDef(labelsColDef: ColDef, readonly?: boolean): ColDef {
  labelsColDef.cellRenderer = LabelRendererV2Component;
  if (readonly) {
    labelsColDef.cellRendererParams = {
      readonly: true,
    };
  }
  labelsColDef.width = 100;
  return labelsColDef;
}

export function addBioregisterIDColDefPropertiesToColDef(bioregisterIDColDef: ColDef): ColDef {
  bioregisterIDColDef.cellRenderer = BioregisterLinkComponent;
  bioregisterIDColDef.width = 100;
  return bioregisterIDColDef;
}

/**
 * Traverses all the tables and returns an array of all the tables that are joined to the given
 * base table.
 */
function getJoinedTables(
  tableInfo: DocumentServiceTableInfoMap,
  table: string,
  previousTables?: string[],
): JoinedTable[] {
  const singleTableInfo = tableInfo[table];

  const currentTable = previousTables
    ? [{ tableName: [table].concat(previousTables).reverse().join(':'), info: singleTableInfo }]
    : [];
  const tables = previousTables ? [table].concat(previousTables) : [];
  const joinedTables = Object.keys(singleTableInfo.joins)
    // Recursion!
    .reduce((agg, key) => agg.concat(getJoinedTables(tableInfo, key, tables)), []);

  return currentTable.concat(joinedTables);
}

function addMatchCountColDefPropertiesToColDef(matchCountColDef: ColDef): ColDef {
  (<ColDef>matchCountColDef).headerName = 'Number of Matches';
  (<ColDef>matchCountColDef).cellRenderer = MatchCountRendererComponent;
  return matchCountColDef;
}

/**
 * Moves the colDef with field after either the name or id column. If neither are found, or if the
 * specified colDef is not found no modification is made.
 */
function moveColDefAfterNameOrIdField(
  input: (ColDef | ColGroupDef)[],
  field: string,
  fallbackIndex: number = fallBackIndex,
) {
  const modified: (ColDef | ColGroupDef)[] = [...input];

  // Find the cell to move.
  const targetIndex = modified.findIndex((col) =>
    isColDef(col) ? col.field.toLowerCase() === field.toLowerCase() : false,
  );
  const target = modified[targetIndex];
  // Remove from original position.
  modified.splice(targetIndex, 1);

  // Different pipelines call their sequences different things. So best to check for a few options.
  // Ideally we put the notes & labels columns directly after the name column but failing that we go for the id column.
  const nameFields = [
    // Antibody Annotator result documents.
    'Name',
    'Sequence Name',
    // Comparison results; summary table.
    'Total Clusters',
    // Comparison results; cluster tables.
    'Score',
  ].map((f) => f.toLowerCase());
  const sequenceNameIndex = modified.findIndex((col) =>
    isColDef(col) ? nameFields.includes(col.field.toLowerCase()) : false,
  );
  // Cluster tables have names like "Heavy V Gene ID" for example which is just as valid as "id".
  const idFieldIndex = modified.findIndex((col) =>
    isColDef(col) ? col.field.toLowerCase().endsWith('id') : false,
  );

  if (!target) {
    // Too bad; have to leave as is.
    return input;
  } else if (sequenceNameIndex !== -1) {
    modified.splice(sequenceNameIndex + 1, 0, target);
    return modified;
  } else if (idFieldIndex !== -1) {
    modified.splice(idFieldIndex + 1, 0, target);
    return modified;
  } else if (modified.length > fallbackIndex) {
    // Good to have these fields not inserted at the start so just put somewhere in there as a fallback.
    modified.splice(fallbackIndex, 0, target);
    return modified;
  } else {
    // Too bad; have to leave as is.
    return input;
  }
}

/**
 * Generates column definitions with column groups for joined columns.
 * Combines both the main table's columns (original) and the joined columns.
 *
 * @param original
 * @param joined
 * @param nameScheme
 */
function generateColumnDefs(
  original: { tableName: string; info: DocumentServiceTableInfo },
  joined: JoinedTable[],
  nameScheme: OrganizationSetting,
): (ColDef | ColGroupDef)[] {
  // Get column groups.
  const columnGroups = original.info.columnGroups || {};
  const columnGroupDefs = Object.values(columnGroups).map((group) =>
    dtsColumnGroupToColGroupDef(original.info, group),
  );

  const originalColumnNames = original.info.columns
    .filter(exclude)
    .filter(
      (col) =>
        !columnGroupDefs.some((group) =>
          group.children.find((child) => isColDef(child) && child.field === col.name),
        ),
    )
    .map((col) => generateColumnDef(col));

  // Filter out name scheme field columns from all columns.
  const columnsWithoutNameSchemes = originalColumnNames.filter((column) =>
    isColDef(column) ? !isNameSchemeColumn(column.field) : true,
  );
  const nameSchemeColumns = originalColumnNames.filter((column) =>
    isColDef(column) ? isNameSchemeColumn(column.field) : false,
  );

  // Create a column group if name scheme columns are present.
  if (nameSchemeColumns.length > 0) {
    const newGroup: ColGroupDef = {
      children: nameSchemeColumns,
      headerName: nameScheme ? nameScheme.name : 'Deleted Name Scheme',
      groupId: nameScheme ? nameScheme.name : 'Deleted Name Scheme',
    };

    columnsWithoutNameSchemes.push(newGroup);
  }

  // Add assay data groups.
  const joinedColumnNames = joined.map((join) => joinedTableToColGroupDef(join));

  return [...columnsWithoutNameSchemes, ...columnGroupDefs, ...joinedColumnNames];
}

export function isNameSchemeColumn(name: string): boolean {
  const regex = new RegExp(/\(from name scheme\)$/);
  return regex.test(name);
}

function exclude(column: DocumentServiceColumn): boolean {
  const candidate = column.name;
  return !['row_index', 'geneious_row_index'].includes(candidate);
}

/**
 * Creates an AG-Grid Column Group from DTS data. This function has been split out from
 * generateColumnDefs so we get stricter typing (no unknown properties) on ColGroupDef.
 *
 * @param tableInfo DTS table info
 * @param group the DTS column group
 * @returns an AG-Grid column group definition
 */
function dtsColumnGroupToColGroupDef(
  tableInfo: DocumentServiceTableInfo,
  group: DocumentServiceTableColumnGroup,
): ColGroupDef {
  const groupName = group.name;
  return {
    groupId: groupName,
    headerName: groupName,
    headerGroupComponent: JoinedTableHeaderComponent,
    children: group.columns.map((colName) =>
      generateColumnDef(tableInfo.columns.find((col) => col.name === colName)),
    ),
  };
}

/**
 * Creates an AG-Grid Column Group from DTS joined table data. This function has been
 * split out from generateColumnDefs so we get stricter typing (no unknown properties)
 * on ColGroupDef.
 *
 * @param join DTS join table info
 * @returns an AG-Grid column group definition
 */
function joinedTableToColGroupDef(join: JoinedTable): ColGroupDef {
  const tableName = join.tableName;
  return {
    groupId: tableName,
    headerName: join.info.displayName,
    headerGroupComponent: JoinedTableHeaderComponent,
    children: join.info.columns.map((col) => generateColumnDef(col, tableName)),
  };
}

function generateColumnDef(col: DocumentServiceColumn, groupName = ''): ColDef {
  // Joined column names (shown as grouped columns in UI) are prepended with the tableName so it needs to map to this column.
  const name = groupName ? generateJoinedColumnID(groupName, col.name) : col.name;
  // TODO 'metadata' temporary for generateDocumentServiceColumn only. Replace with a better solution in BX-5385
  return {
    field: name,
    headerName: col.displayName,
    headerTooltip: col.metadata?.description ?? col.displayName,
    metadata: { type: col.dataType.kind },
  } as ColDef;
}

interface JoinedTable {
  tableName: string;
  info: DocumentServiceTableInfo;
}
