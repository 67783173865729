<bx-chart
  [chartOptions]="chartOptions"
  [title]="titleOptions"
  [xAxis]="xAxisOptions"
  [yAxis]="yAxisOptions"
  [tooltip]="tooltipOptions"
  [legend]="legendOptions"
  [plotOptions]="plotOptions"
  [series]="processedSeries"
  [colors]="colors"
  [message]="message"
  (chartLoaded)="graphLoaded.emit($event.container?.firstElementChild)"
>
</bx-chart>
