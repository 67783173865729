import { SelectionState, SelectionStateV2 } from '../../features/grid/grid.component';
import { AnnotatedPluginDocument } from '../geneious';
import { getRowIdentifier } from '../ngs/getRowIdentifier';
import { FolderTreeItem } from '../folders/models/folder.model';
import {
  isViewerDocumentSelection,
  ViewerDocumentSelection,
  ViewerMultipleTableDocumentSelection,
} from './viewer-document-data';
import { DocumentTableType } from '../../../nucleus/services/documentService/document-table-type';

export function viewerSelectionToSelectionState(
  viewerSelection: ViewerDocumentSelection | ViewerMultipleTableDocumentSelection,
): SelectionState {
  if (isViewerDocumentSelection(viewerSelection)) {
    return {
      noOfRowsSelected: viewerSelection.totalSelected,
      totalNoOfRows: viewerSelection.total,
      selectAll: false,
      ids: viewerSelection.rows.map((row) => row.id),
      selectedRows: viewerSelection.rows,
      firstRow: null,
    };
  } else {
    return {
      noOfRowsSelected: viewerSelection.totalSelected,
      totalNoOfRows: viewerSelection.total,
      selectAll: viewerSelection.selectAll,
      ids: viewerSelection.rows.map((row) => getRowIdentifier(row)),
      selectedRows: viewerSelection.selectedRows,
      firstRow: null,
    };
  }
}

export function viewerSelectionToSelectionStateV2(
  viewerSelection: ViewerDocumentSelection | ViewerMultipleTableDocumentSelection,
): SelectionStateV2<AnnotatedPluginDocument> {
  if (isViewerDocumentSelection(viewerSelection)) {
    return {
      ...viewerSelection,
      selectAll: false,
      ids: viewerSelection.rows.map((row) => row.id),
      selectedRows: viewerSelection.rows,
    };
  } else {
    return {
      ...viewerSelection,
      ids: viewerSelection.rows.map((row) => getRowIdentifier(row)),
    };
  }
}

export function selectionStateV2ToViewerMultipleTableDocumentSelection(
  state: SelectionStateV2,
  tableType: DocumentTableType,
  document: AnnotatedPluginDocument,
): ViewerMultipleTableDocumentSelection {
  return {
    ...state,
    tableType: tableType,
    document: document,
  };
}

export function selectionStateToViewerDocumentSelection(
  state: SelectionState,
  folder: FolderTreeItem,
): ViewerDocumentSelection {
  return {
    rows: state.selectedRows.map((row) => AnnotatedPluginDocument.fromNucleusEntity(row, folder)),
    total: state.totalNoOfRows,
    totalSelected: state.noOfRowsSelected,
  };
}
