export function quantile(arr: number[], q: number): number {
  const sorted = [...arr].sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
}

/**
 * Converts degrees to radians.
 *
 * @param angleInDegrees angle in degrees
 * @returns angle in radians
 */
export function toRadians(angleInDegrees: number) {
  return (angleInDegrees / 180) * Math.PI;
}
