import { asyncScheduler, Observable, observeOn, of as observableOf } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HeatmapData } from '../../../features/graphs/graph-heatmap/graph-heatmap.component';
import { DocumentUtils } from '../../document-utils';
import { SelectionStateV2 } from '../../../features/grid/grid.component';
import { viewerSelectionToSelectionStateV2 } from '../../viewer-components/viewers-helper';
import { GenericReportModel } from '../../viewer-components/json-report-viewer/generic-report.models';
import { IBarChartInfo } from '../../../features/graphs/column-chart/BarChartInfo.model';
import { BoxplotData } from '../../../features/graphs/graph-boxplot/graph-boxplot.component';
import { ViewerDocumentSelection } from '../../viewer-components/viewer-document-data';
import { SeriesColumnOptions } from 'highcharts';
import { GraphDocumentDataService } from '../../../features/graphs/graph-document-data.service';
import {
  naturalSortCompare,
  sortAntibodyRegionByName,
  sortGeneCombinationName,
} from '../../../shared/sort.util';
import { quantile } from '../../../shared/utils/math-utils';
import { NgsReportBarChartType } from './ngs-report.model';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { AnnotatedPluginDocument } from '../../geneious';
import {
  GeneCombinationsHeatmapService,
  geneNameWithDbPrefix,
  RowWithGene,
} from '../../../features/graphs/gene-combinations-heatmap.service';
import {
  ClusterSummaryDataFromReport,
  GeneFamilyData,
  GraphDataFor,
} from '../ngs-graphs/ngs-graphs.model';
import { sanitizeDTSTableOrColumnName } from '../../../../nucleus/services/documentService/document-service.v1';

@Injectable({
  providedIn: 'root',
})
export class NgsReportService {
  private cachedReport?: ReportCache;
  private cachedClusterSummaries?: ClusterSummaryCache;
  constructor(private documentHttpService: DocumentHttpV2Service) {}

  getSelectedReport(
    viewerSelection: ViewerDocumentSelection,
  ): Observable<GenericReportModel | null> {
    const state: SelectionStateV2<AnnotatedPluginDocument> =
      viewerSelectionToSelectionStateV2(viewerSelection);
    return this.getFirstReport(state.selectedRows);
  }

  getFirstReport(docs: any[]): Observable<GenericReportModel | null> {
    const ngsResults = docs.filter((doc) => DocumentUtils.isResultDoc(doc));
    if (ngsResults.length) {
      const firstNgs = ngsResults[0];
      return this.getReportByID(firstNgs.id);
    } else {
      return observableOf(null);
    }
  }

  getReportByID(resultID: string): Observable<GenericReportModel> {
    return this.getReportBlobByID(resultID).pipe(
      map((report) => NgsReportService.convertToUIReport(report, resultID)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  getReportBlobByID(documentID: string) {
    if (documentID === this.cachedReport?.documentID) {
      return this.cachedReport.report$;
    }

    const report$ = this.documentHttpService
      .getDocumentPart(documentID, 'NGS_REPORT', 'json')
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }), observeOn(asyncScheduler));

    this.cachedReport = {
      documentID: documentID,
      report$: report$,
    };
    return report$;
  }

  getClusterSummaryById(documentID: string, tableName: string) {
    if (
      documentID === this.cachedClusterSummaries?.documentID &&
      tableName in (this.cachedClusterSummaries?.clusterSummaries ?? {})
    ) {
      return this.cachedClusterSummaries.clusterSummaries[tableName];
    }
    const clusterSummary$: Observable<ClusterSummaryDataFromReport> = this.documentHttpService
      .getDocumentPart(
        documentID,
        `${sanitizeDTSTableOrColumnName(tableName).replaceAll(' ', '_')}_CLUSTER_SUMMARY`,
        'json',
      )
      .pipe(
        map(({ data }) => data),
        shareReplay({ refCount: true, bufferSize: 1 }),
        observeOn(asyncScheduler),
      );
    if (this.cachedClusterSummaries?.documentID !== documentID) {
      this.cachedClusterSummaries = {
        documentID,
        clusterSummaries: {},
      };
    }
    this.cachedClusterSummaries.clusterSummaries[tableName] = clusterSummary$;
    return clusterSummary$;
  }

  static convertToUIReport(reportData: any, resultID: string): GenericReportModel {
    if (typeof reportData.job.parameters === 'string') {
      try {
        reportData.job.parameters = JSON.parse(reportData.job.parameters);
      } catch (err) {
        // If params are malformed, just ignore them.
        reportData.job.parameters = {};
      }
    }

    const allTables = Object.keys(reportData.statistics.overview);
    const genericReportTablePrefix = 'Report#';
    const genericBarCharts = [];
    for (const tableName of allTables) {
      if (tableName.startsWith(genericReportTablePrefix)) {
        const title = tableName.substring(genericReportTablePrefix.length);
        const table = reportData.statistics.overview[tableName];
        genericBarCharts.push(this.getGenericBarChart(table, title));
      }
    }
    const reportDoc: GenericReportModel = {
      name: 'Report',
      description: '',
      textAtBeginningOfReport: reportData.statistics.overview['reportSummary'],
      documentID: resultID,
      warnings: reportData.job.warnings || [],
      params: [],
      boxPlots: [],
      stackedBarCharts: [],
      barCharts: genericBarCharts
        .concat([
          this.getBarChartOfType(reportData, NgsReportBarChartType.AnnotationRate),
          this.getBarChartOfType(reportData, NgsReportBarChartType.AnnotationRateEverything),
          this.getBarChartOfType(reportData, NgsReportBarChartType.NumberClusters),
          this.getBarChartOfType(reportData, NgsReportBarChartType.NumberClustersNucleotide),
        ])
        .filter((chart) => !!chart),
      heatmaps: Object.keys(reportData.statistics.geneCombinations)
        .sort(sortGeneCombinationName)
        .map((name) => ({
          ...GeneCombinationsHeatmapService.getGeneCombinationTitleAndAxes(name),
          data: this.transformHeatmapData(reportData.statistics.geneCombinations[name]),
        })),
      numberInputSequences: 0,
    };

    if (reportData.statistics.mutationDistributionByGene) {
      const mutationDistribution:
        | GeneDistributionData[]
        | {
            [chain: string]: GeneDistributionData[];
          } = reportData.statistics.mutationDistributionByGene;
      if (Array.isArray(mutationDistribution)) {
        reportDoc.boxPlots.push({
          title: `Mutation Distribution By Gene`,
          data: this.transformBoxPlotData(mutationDistribution),
        });
      } else {
        Object.keys(mutationDistribution)
          .sort(sortAntibodyRegionByName)
          .forEach((chain) => {
            if (mutationDistribution[chain].length > 0) {
              reportDoc.boxPlots.push({
                title: `Mutation Distribution By Gene: ${chain}`,
                data: this.transformBoxPlotData(mutationDistribution[chain]),
              });
            }
          });
      }
    }

    if (reportData.statistics.geneFamilyUsage) {
      const isSplitByIsoType = Object.keys(reportData.statistics.geneFamilyUsage)
        .map((key) => !!geneFamilyNameMap(key))
        .some((x) => x);

      // Catch first implementation of this report which wasn't split by isotypes.
      if (!isSplitByIsoType) {
        reportDoc.stackedBarCharts.push({
          title: 'Gene Family Usage',
          data: this.transformStackedBarPlotData(reportData.statistics.geneFamilyUsage, 1),
        });
      } else {
        Object.keys(reportData.statistics.geneFamilyUsage)
          .sort(sortAntibodyRegionByName)
          .filter((name) => !!geneFamilyNameMap(name))
          .forEach((name) => {
            const rawData = reportData.statistics.geneFamilyUsage[name];
            const total = NgsReportService.sumStackedBarPlotData(rawData);
            reportDoc.stackedBarCharts.push({
              title: `${geneFamilyNameMap(name)} Family Usage`,
              data: this.transformStackedBarPlotData(rawData, total),
            });
          });
      }
    }

    // Add a cluster diversity graph for each CDR3 region
    const diversityCharts = this.getDiversityBarCharts(reportData.statistics.clusterDiversities);
    diversityCharts.forEach((chart) => reportDoc.barCharts.push(chart));

    return reportDoc;
  }

  static getGenericBarChart(chartData: any, name: string) {
    const xLabel = Object.keys(chartData[0])[0];
    const yLabel = Object.keys(chartData[0])[1];
    return this.buildGenericBarChart(name, xLabel, yLabel, chartData);
  }

  static getBarChartOfTypeWithAxis(
    report: any,
    yAxis: any,
    jsonChart: any,
    type?: string,
  ): IBarChartInfo | null {
    const sortedReport = {
      ...report,
      statistics: {
        ...report.statistics,
        overview: {
          ...report.statistics.overview,
          annotationRates: report.statistics.overview.annotationRates?.sort((a: any, b: any) =>
            sortAntibodyRegionByName(a.Region, b.Region),
          ),
          clusters: report.statistics.overview.clusters?.sort((a: any, b: any) =>
            sortAntibodyRegionByName(a.Region, b.Region),
          ),
          genes: report.statistics.overview.genes?.sort((a: any, b: any) =>
            sortAntibodyRegionByName(a.Region, b.Region),
          ),
        },
      },
    };

    const isPercentage = yAxis.includes('%');

    if (jsonChart === 'annotationRates') {
      const annotationRatesData = report.statistics.overview[jsonChart];
      if (!annotationRatesData) {
        return null;
      }
      const yLabel = isPercentage ? ' % of Sequences' : ' Number of Sequences';
      const title = this.composeChartTitleWithYAxis('Annotation Rates', yAxis);
      return this.buildBarChart(title, 'Region', yLabel, yAxis, annotationRatesData);
    }

    if (jsonChart === 'clusters' && type === 'aminoacid') {
      const aaClusters = sortedReport.statistics.overview['clusters'].filter(
        (c: any) => !c.Region.includes('Nucleotides'),
      );

      const title = this.composeChartTitleWithYAxis('Number of Amino Acid Clusters', yAxis);
      return this.buildBarChart(title, 'Cluster Region', 'Number of Clusters', yAxis, aaClusters);
    }

    if (jsonChart === 'clusters' && type === 'nucleotide') {
      const nucleotideClusters = sortedReport.statistics.overview['clusters']
        .filter((c: any) => c.Region.includes('Nucleotides'))
        .map((c: any) => ({ ...c, Region: c.Region.replace('Nucleotides', '') }))
        // TODO: filter before sort so we can avoid sort it the second time
        .sort((a: any, b: any) => sortAntibodyRegionByName(a.Region, b.Region));

      const title = this.composeChartTitleWithYAxis('Number of Nucleotide Clusters', yAxis);
      return this.buildBarChart(
        title,
        'Cluster Region',
        'Number of Clusters',
        yAxis,
        nucleotideClusters,
      );
    }

    const data = sortedReport.statistics.overview[jsonChart];
    return this.buildBarChart(yAxis, 'Region', `Number of ${jsonChart}`, yAxis, data);
  }

  static composeChartTitleWithYAxis(title: string, yAxis: string): string {
    const yAxisTitleMap: Map<string, string> = new Map();
    yAxisTitleMap.set('Clusters Fully Annotated', 'Fully Annotated');
    yAxisTitleMap.set('Clusters In Frame & Fully Annotated', 'In Frame & Fully Annotated');
    yAxisTitleMap.set(
      'Clusters Without Stop Codons & In Frame & Fully Annotated',
      'Without Stop Codons & In Frame & Fully Annotated',
    );
    // If we encounter this yAxis, the title is usually enough so we don't need to combine it with a yAxis.
    yAxisTitleMap.set('Number of Clusters', '');

    const subTitle = yAxisTitleMap.get(yAxis);

    if (subTitle) {
      return `${title} (${subTitle})`;
    }

    if (subTitle === '') {
      return title;
    }

    return `${title} (${yAxis})`;
  }

  static buildBarChart(
    title: string,
    xLabel: string,
    yLabel: string,
    yAxis: string,
    rawData: any,
  ): IBarChartInfo {
    const DEFAULT_XKEY = 'Region';
    const data = GraphDocumentDataService.transformBarChartData(rawData, DEFAULT_XKEY, yAxis);
    return { title, xLabel, yLabel, data };
  }

  static buildGenericBarChart(
    title: string,
    xLabel: string,
    yLabel: string,
    rawData: any,
  ): IBarChartInfo {
    const data = GraphDocumentDataService.transformBarChartData(rawData, xLabel, yLabel);
    return { title, xLabel, yLabel, data };
  }

  static getBarChartOfType(reportData: any, type: NgsReportBarChartType): IBarChartInfo | null {
    const YKEYS = {
      annotation_rates: 'Annotated',
      annotation_rates_everything: '% Without Stop Codons & In Frame & Fully Annotated',
      cluster_numbers: 'Number of Clusters',
    };

    const [jsonChart, yKey, chartType] = (() => {
      switch (type) {
        case NgsReportBarChartType.AnnotationRate:
          return ['annotationRates', YKEYS.annotation_rates];
        case NgsReportBarChartType.AnnotationRateEverything:
          return ['annotationRates', YKEYS.annotation_rates_everything];
        case NgsReportBarChartType.NumberClusters:
          return ['clusters', YKEYS.cluster_numbers, 'aminoacid'];
        case NgsReportBarChartType.NumberClustersNucleotide:
          return ['clusters', YKEYS.cluster_numbers, 'nucleotide'];
        default:
          // case: 'cluster_numbers'
          return ['clusters', YKEYS.cluster_numbers];
      }
    })();

    return this.getBarChartOfTypeWithAxis(reportData, yKey, jsonChart, chartType);
  }

  static getDiversityBarCharts(clusterDiversities: any[]) {
    const cdr3Regions: any[] = [];
    clusterDiversities.forEach((row) => {
      if (row.Region.toLowerCase().includes('cdr3') && !cdr3Regions.includes(row.Region)) {
        cdr3Regions.push(row.Region);
      }
    });
    return cdr3Regions.map((region: string) => {
      const regionData = clusterDiversities.filter((row) => row.Region === region);
      return {
        title: region.replace('Cluster Diversity', '') + ' Cluster Diversity',
        xLabel: 'Cluster size',
        yLabel: ' Number of clusters',
        data: GraphDocumentDataService.transformBarChartData(regionData, 'Cluster Size', 'Count'),
      };
    });
  }

  static transformBoxPlotData(data: GeneDistributionData[]): BoxplotData {
    const formattedData = data.map((entry) => {
      // TODO: Remove this once we migrated all NGS_REPORT blob to a new format (BX-7079)
      if ('differences' in entry) {
        const rawData = entry.differences.split(',').map((value) => Number(value));
        return [
          entry.gene,
          quantile(rawData, 0),
          quantile(rawData, 0.25),
          quantile(rawData, 0.5),
          quantile(rawData, 0.75),
          quantile(rawData, 1),
        ];
      }

      return [entry.gene, ...entry.differencesQuantiles];
    });
    return {
      title: '',
      label: '',
      data: [
        {
          type: 'boxplot',
          data: formattedData,
        },
      ],
    };
  }

  static sumStackedBarPlotData(data: any): number {
    const keys = Object.keys(data);

    // Calculate the total number of data points in the current plot.
    let totalCount = 0;
    keys.forEach((family) => {
      const counts: number[] = Object.values(data[family]);
      totalCount += counts.reduce((sum, current) => sum + current, 0);
    });

    return totalCount;
  }

  static transformStackedBarPlotData(
    data: { [key: string]: { [key: string]: number } },
    totalCount: number,
  ): SeriesColumnOptions[] {
    return Object.entries(data)
      .map(([family, counts]) => {
        const sorted = Object.entries(counts)
          .filter(([, value]) => !!value)
          .sort(([a], [b]) => naturalSortCompare(a, b));
        return {
          family: family,
          counts: sorted,
        };
      })
      .sort((a, b) =>
        a.family.localeCompare(b.family, undefined, { numeric: true, sensitivity: 'base' }),
      )
      .reduce((prev, { family, counts }) => {
        const series = counts.map(([key, value]) => {
          return {
            name: key,
            data: [[family, (value / totalCount) * 100]],
            type: 'column',
          };
        });
        return prev.concat(series);
      }, []);
  }

  // TODO Deduplicate from GraphUtilService.rowsToHeatmap().
  static transformHeatmapData(data: RowWithGene[], transposed: boolean = false): HeatmapData {
    let labels = {
      x: data.map((row) => geneNameWithDbPrefix(row.Gene)),
      y: GeneCombinationsHeatmapService.filterGene(data[0]).map(geneNameWithDbPrefix),
    };
    let formattedData = data
      .map((d) => {
        const keys = GeneCombinationsHeatmapService.filterGene(d);
        return keys.map((key) => {
          return [
            labels.x.indexOf(geneNameWithDbPrefix(d.Gene)),
            labels.y.indexOf(geneNameWithDbPrefix(key)),
            // The + in necessary to even though the values are number because for some reason it gets turned into a string.
            +d[key],
          ];
        });
      })
      .reduce((a, b) => a.concat(b), []);
    if (transposed) {
      labels = {
        x: labels.y,
        y: labels.x,
      };
      formattedData = formattedData.map(([a, b, c]) => [b, a, c]);
    }
    return {
      labels: labels,
      tooltipLabel: 'Number of Sequences',
      series: { type: 'heatmap', data: formattedData },
    };
  }
}

type ReportCache = {
  documentID: string;
  report$: Observable<GenericReportModel>;
};

type ClusterSummaryCache = {
  documentID: string;
  clusterSummaries: Record<string, Observable<ClusterSummaryDataFromReport>>;
};
type GeneDistributionData =
  | { gene: string; differences: string }
  | { gene: string; differencesQuantiles: number[] };
const allHeavyLightPattern = /(.*)All(Heavy|Light)/;
const heavyLightGenePattern = /(.*)(Heavy|Light) (V|J) Gene/;

/**
 * Converts a key from the gene family usage part of the ngs report to the appropriate format for display
 * @param reportKey
 */
export function geneFamilyNameMap(reportKey: string): string {
  const newFormatMatch = reportKey.match(heavyLightGenePattern);
  if (newFormatMatch !== null) {
    return reportKey;
  }
  const match = reportKey.match(allHeavyLightPattern);
  if (match === null || match.length === 0) {
    return null;
  }
  return match[0].replace(allHeavyLightPattern, '$1$2 V Gene');
}

/**
 * Converts a gene family name to the corresponding report key.
 * Only applicable where we expect the report to contain "AllHeavy"/"AllLight" keys
 * @param name
 */
export function reverseGeneFamilyNameMap(name: string): string {
  const match = name.match(heavyLightGenePattern);
  if (match === null || match.length === 0) {
    return null;
  }
  return match[0].replace(heavyLightGenePattern, '$1All$2');
}

export function getGeneFamilyDataFromReport(
  report: GraphDataFor<'geneFamilyUsage'>,
  geneFamily: string,
) {
  // Catch first implementation of this report which wasn't split by isotypes.
  const isSplitByIsoType = Object.keys(report.statistics.geneFamilyUsage)
    .map((key) => !!geneFamilyNameMap(key))
    .some((x) => x);
  // New reports (since BX-7815) will have keys like "Heavy V Gene", rather than "AllHeavy"
  const isNewReportKey = Object.keys(report.statistics.geneFamilyUsage)
    .map((key) => geneFamilyNameMap(key) === key)
    .some((x) => x);
  const geneFamilyKey = isNewReportKey ? geneFamily : reverseGeneFamilyNameMap(geneFamily);
  const rawData =
    !isSplitByIsoType || geneFamilyKey === null
      ? report.statistics.geneFamilyUsage
      : report.statistics.geneFamilyUsage[geneFamilyKey];

  const total = NgsReportService.sumStackedBarPlotData(rawData);
  return NgsReportService.transformStackedBarPlotData(rawData as GeneFamilyData, total);
}
