import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadBlobService {
  constructor() {}

  /**
   * Downloads a javascript blob to disk. Copied from a jsfiddle.
   *
   * @see https://jsfiddle.net/koldev/cW7W5/
   */
  static download(fileName: string, data: any) {
    const link = document.createElement('a');
    document.body.appendChild(link);
    (link as any).style = 'display: none';
    const blob = new Blob([data], { type: 'text/csv' });
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}
