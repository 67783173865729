import { Observable } from 'rxjs';

export const observeResize = (
  element: HTMLElement,
): Observable<{ width: number; height: number }> =>
  new Observable<{ width: number; height: number }>((observer) => {
    const resizeObserver = new ResizeObserver(() => {
      observer.next({ width: element.clientWidth, height: element.clientHeight });
    });
    resizeObserver.observe(element);

    return function unsubscribed() {
      resizeObserver.disconnect();
    };
  });
