import { BulkRowUpdate, BulkSelectionWithQuery } from '@geneious/nucleus-api-client';
import { createReducer, on } from '@ngrx/store';
import * as DTEActions from './document-table-edits.actions';

export const documentTableEditsFeatureKey = 'documentTableEdits';

export type EditStatus =
  /** Cell has been edited by the user, and the edit is only present in the store. */
  | 'userEdited'
  /** Edit has been sent in an update to Nucleus but not yet acknowledged */
  | 'requestSent'
  /** Edit has been sent in an update to Nucleus, and the update is now applying in Elasticsearch */
  | 'requestAcknowledged'
  /** Edit has been applied in Elasticsearch and confirmed via websocket event */
  | 'completed';

export type Cell = {
  row: number;
  column: string;
};

export type ReplaceValueEdit<T = unknown> = { value: T };
export type AddValueEdit<T = unknown> = { add: T };
export type Edit<T = unknown> = ReplaceValueEdit<T> | AddValueEdit<T>;

/** Base type for Cell and Bulk Edits in the store. */
export type EditMetadata = {
  /** Used by the store effects to track the progress of applying the edit in the backend */
  status: EditStatus;
  /** The time that the edit occurred  */
  editTimestamp: number;
};

/**
 * Currently, all cell edits are ReplaceValueEdits. This means we only need to
 * store the last submitted edit to figure out the cell state. It also simplifies
 * the effects, allowing them to use the replaceCellValue API and its associated
 * activity event.
 */
export type StoredCellEdit<T = unknown> = Cell & EditMetadata & { edit: ReplaceValueEdit<T> };

/**
 * Bulk edits can be ReplaceValueEdits or AddValueEdits. We store all bulk edits
 * that were created or completed since the table was last loaded. These edits
 * are then applied in chronological order in the selectors to get the cell state.
 */
export type StoredBulkEdit<T = unknown> = EditMetadata & {
  edit: Edit<T>;
  /**
   * The column that the edit targets. Bulk row update requests can contain many
   * edits for many rows & many columns, but this store only deals with requests
   * that contain one edit, one column, and many rows.
   */
  column: string;
  /**
   * The rows that will be affected by the update. Note this is calculated by the
   * UI and may differ from the `selection.rowNumbers` property of the API
   * request body.
   */
  rows: number[];
  /**
   * When true, rowNumbers is the set of rows to include in the selection. When
   * false, rowNumbers is excluded from the selection. This is calculated by the
   * UI and may differ from the `selection.selectAll` property of the API request
   * body.
   */
  selectAll: boolean;
};

/** Contains all of the edits for a single document table. */
export type DocumentTableEdits = {
  /**
   * A record of cell edits, indexed by a "cellID", which is a composite key
   * containing a cell's row and column (see {@link getCellID}). Only the latest
   * edit is stored per cell.
   */
  cellEdits: { [cellID: string]: StoredCellEdit };
  /**
   * An array of bulk edits that have been applied to this document table.
   * They are stored in reverse chronological order, so the newest edit
   * is at index 0.
   * NOTE: this store assumes there will only ever be one incomplete bulk edit
   * per table at one time. This is because the DocumentTableBulkRowUpdateCompletedEvent
   * notification only contains enough info to identify the table, not a specific
   * edit.
   */
  bulkEdits: StoredBulkEdit[];
};

/** Type definition for the documentTableEdits store state. */
export interface DocumentTableEditsState {
  [documentID: string]: {
    [tableName: string]: DocumentTableEdits;
  };
}

export type DocumentTableID = {
  documentID: string;
  tableName: string;
};

export type DocumentTableCell = DocumentTableID & Cell;

/**
 * Returns the cell ID, which is used as the key for each document table's edit state.
 * @param cell the cell object
 * @returns the cell ID
 */
export function getCellID(cell: { row: number; column: string }): string {
  return `${cell.row}:${cell.column}`;
}

type Updater<T> = (existingState: T | undefined) => T | undefined;

/**
 * Applies an updater function to the state under the specified document ID, and
 * returns the updated documentTableEdits store state. If the updater returns
 * undefined or an empty object, the [documentID] property will be removed from
 * the store.
 *
 * @param state The state of the documentTableEdits store
 * @param documentID The document to replace state for
 * @param updater A function that accepts old state and returns new state
 * @returns Updated state for the documentTableEdits store
 */
function replaceStateForDocument(
  state: DocumentTableEditsState,
  documentID: string,
  updater: Updater<DocumentTableEditsState[string]>,
): DocumentTableEditsState {
  // Pluck the document state (or undefined if it doesn't exist)
  const { [documentID]: documentState, ...otherDocuments } = state ?? {};
  const updatedDocumentState = updater(documentState);
  // If no changes were made, return original state
  if (updatedDocumentState === documentState) {
    return state;
  }
  // Check if document still has tables in it
  if (!updatedDocumentState || Object.keys(updatedDocumentState).length === 0) {
    // If not, remove the document from the store
    return otherDocuments;
  }
  return { ...otherDocuments, [documentID]: updatedDocumentState };
}

/**
 * Applies an updater function to the state under the specified document table,
 * and returns the updated documentTableEdits store state. If the updater
 * returns undefined or an empty object, the [tableName] property will be
 * removed from the document state.
 *
 * @param state The state of the documentTableEdits store
 * @param documentTable The document table to replace state for
 * @param updater A function that accepts old state and returns new state
 * @returns Updated state for the documentTableEdits store
 */
function replaceStateForDocumentTable(
  state: DocumentTableEditsState,
  { documentID, tableName }: DocumentTableID,
  updater: Updater<DocumentTableEdits>,
): DocumentTableEditsState {
  return replaceStateForDocument(state, documentID, (documentState = {}) => {
    // Pluck the table state (or initialize if it doesn't exist)
    const { [tableName]: tableState = { cellEdits: {}, bulkEdits: [] }, ...otherTables } =
      documentState;
    const updatedTableState = updater(tableState);
    // If no changes were made, return original state
    if (updatedTableState === tableState) {
      return documentState;
    }
    // Check if table still has edits in it - if not, we want to remove the property
    if (
      (updatedTableState?.bulkEdits && updatedTableState.bulkEdits.length > 0) ||
      (updatedTableState?.cellEdits && Object.keys(updatedTableState.cellEdits).length > 0)
    ) {
      return { ...otherTables, [tableName]: updatedTableState };
    }
    return otherTables;
  });
}

/**
 * Applies an updater function to the state for the specified cellEdit, and
 * returns the updated documentTableEdits store state. If the updater returns
 * undefined or an empty object, the [cellID] property will be removed from the
 * cellEdits for that document table.
 *
 * @param state The state of the documentTableEdits store
 * @param cell The cell to replace cellEdit state for
 * @param updater A function that accepts old state and returns new state
 * @returns Updated state for the documentTableEdits store
 */
function replaceStateForDocumentTableCell(
  state: DocumentTableEditsState,
  cell: DocumentTableCell,
  updater: Updater<StoredCellEdit>,
): DocumentTableEditsState {
  return replaceStateForDocumentTable(state, cell, (tableState) => {
    const cellEdits = tableState?.cellEdits ?? {};
    const cellID = getCellID(cell);
    // Pluck the cell edit state (or undefined if it doesn't exist)
    const { [cellID]: cellEditState, ...otherCellEdits } = cellEdits;
    const updatedCellEditState = updater(cellEditState);
    // If no changes were made, return original state
    if (updatedCellEditState === cellEditState) {
      return tableState;
    }
    // Check if updated edit is defined
    if (!updatedCellEditState) {
      // If not, remove it from the cellEdits object
      return {
        ...tableState,
        cellEdits: otherCellEdits,
      };
    }
    return {
      ...tableState,
      cellEdits: {
        ...otherCellEdits,
        [cellID]: updatedCellEditState,
      },
    };
  });
}

/** Initial state for the documentTableEdits store */
export const initialState: DocumentTableEditsState = {};

/**
 * The maximum number of rows that can be selected for a bulk update. The back-end
 * has been tested with 1 million rows, which worked, but took 6 minutes to apply.
 *
 * The current limit of 10,000 rows has been chosen for the following reasons:
 * - The bulk update API only supports Lucene query strings, whereas the document
 *    table query API only supports SQL. We encountered issues converting the
 *    query in the UI (see BX-7591), so for now we just supply a list of
 *    rowNumbers without a query. The maximum number of rowNumbers that the API
 *    supports is 10,000.
 * - The UI needs a list of affected rows to present the updates optimistically.
 *    It sometimes needs to execute the query to get this list. If we were to
 *    support 100,000 rows (for example), this would require 10 requests which would
 *    be noticeably slow. Very large lists would also affect selector performance.
 */
export const MAX_ROWS_IN_BULK_UPDATE = 10_000;

/** Reducer for the documentTableEdits store */
export const reducer = createReducer(
  initialState,
  on(DTEActions.replaceCellValueEdit, (state, action) =>
    replaceStateForDocumentTableCell(state, action, () => ({
      row: action.row,
      column: action.column,
      edit: { value: action.value },
      editTimestamp: Date.now(),
      status: 'userEdited',
    })),
  ),
  on(DTEActions.replaceCellValuePostRequest, (state, action) =>
    replaceStateForDocumentTableCell(state, action, (existingState) => {
      if (!existingState || existingState.status !== 'userEdited') {
        return existingState;
      }
      return { ...existingState, status: 'requestSent' };
    }),
  ),
  on(DTEActions.replaceCellValuePostRequestAcked, (state, action) =>
    replaceStateForDocumentTableCell(state, action, (existingState) => {
      if (!existingState || existingState.status !== 'requestSent') {
        return existingState;
      }
      return { ...existingState, status: 'requestAcknowledged' };
    }),
  ),
  on(DTEActions.replaceCellValueEditSuccess, (state, action) =>
    replaceStateForDocumentTableCell(state, action, (existingState) => {
      if (!existingState || existingState.status !== 'requestAcknowledged') {
        return existingState;
      }
      return { ...existingState, status: 'completed' };
    }),
  ),
  on(DTEActions.replaceCellValueEditFailed, (state, action) =>
    replaceStateForDocumentTableCell(state, action, (existingState) => {
      if (existingState?.status === 'userEdited') {
        return existingState;
      }
      return undefined;
    }),
  ),
  on(DTEActions.documentTableFocusLost, (state, action) =>
    replaceStateForDocumentTable(state, action, (tableState) => {
      const cellEditEntries = Object.entries(tableState?.cellEdits ?? {});
      const incompleteEditEntries = cellEditEntries.filter(
        ([_, cellState]) => cellState.status !== 'completed',
      );
      const bulkEdits = tableState.bulkEdits ?? [];
      const incompleteBulkEdits = bulkEdits.filter((edit) => edit.status !== 'completed');
      if (
        cellEditEntries.length === incompleteEditEntries.length &&
        bulkEdits.length === incompleteBulkEdits.length
      ) {
        return tableState;
      }
      return {
        bulkEdits: incompleteBulkEdits,
        cellEdits: Object.fromEntries(incompleteEditEntries),
      };
    }),
  ),

  on(DTEActions.bulkRowUpdatePostRequest, (state, action) =>
    replaceStateForDocumentTable(state, action, (existingState) => {
      let bulkEdits = existingState?.bulkEdits ?? [];
      // If this is a retry, remove the previous attempt
      if (bulkEdits[0]?.status === 'requestSent') {
        bulkEdits = bulkEdits.slice(1);
      }
      return {
        ...existingState,
        bulkEdits: [
          {
            edit: action.edit,
            editTimestamp: Date.now(),
            rows: action.rows,
            column: action.column,
            selectAll: action.selectAll,
            status: 'requestSent',
          },
          ...bulkEdits,
        ],
      };
    }),
  ),
  on(DTEActions.bulkRowUpdatePostRequestAcked, (state, action) =>
    replaceStateForDocumentTable(state, action, (existingState) => {
      const [incompleteEdit, ...otherEdits] = existingState?.bulkEdits ?? [];
      if (incompleteEdit?.status !== 'requestSent') {
        return existingState;
      }
      const updatedBulkEdit: StoredBulkEdit = {
        ...incompleteEdit,
        status: 'requestAcknowledged',
      };
      return {
        ...existingState,
        bulkEdits: [updatedBulkEdit, ...otherEdits],
      };
    }),
  ),
  on(DTEActions.bulkRowUpdateEditSuccess, (state, action) =>
    replaceStateForDocumentTable(state, action, (existingState) => {
      const [incompleteEdit, ...otherEdits] = existingState?.bulkEdits ?? [];
      if (incompleteEdit?.status !== 'requestAcknowledged') {
        return existingState;
      }
      const updatedBulkEdit: StoredBulkEdit = {
        ...incompleteEdit,
        status: 'completed',
      };
      return {
        ...existingState,
        bulkEdits: [updatedBulkEdit, ...otherEdits],
      };
    }),
  ),
  on(DTEActions.bulkRowUpdateEditFailed, (state, action) =>
    replaceStateForDocumentTable(state, action, (existingState) => {
      const [incompleteEdit, ...otherEdits] = existingState?.bulkEdits ?? [];
      if (incompleteEdit.status === 'completed') {
        return existingState;
      }
      return {
        ...existingState,
        bulkEdits: otherEdits,
      };
    }),
  ),
);
