import { roundForGraph } from '../../../shared/utils/rounding';
import { TooltipFormatterCallbackFunction } from 'highcharts';

export const columnTooltipFormatter: TooltipFormatterCallbackFunction = function () {
  const xAxisLabel = this.series.xAxis.options.title.text;
  const yAxisLabel = this.series.yAxis.options.title.text;
  // The y axis label for Antibody Annotator data contains a '%' symbol if it's in percentage units.
  const isPercentage = yAxisLabel?.includes('%');
  const yValueRounded = `${roundForGraph(this.y)}${isPercentage ? '%' : ''} (3 s.f.)`;
  const xAxisLabelEmpty = !xAxisLabel || xAxisLabel.trim().length === 0;
  const yAxisLabelEmpty = !yAxisLabel || yAxisLabel.trim().length === 0;
  return `${xAxisLabelEmpty ? '' : xAxisLabel + ': '}${this.key}<br/>${
    yAxisLabelEmpty ? '' : yAxisLabel + ': '
  }${yValueRounded}`;
};
