import { OrganizationSetting } from '../models/settings/setting.model';

export type Label = {
  id: string;
  name: string;
  color: string;
};

export function orgSettingToLabel(setting: OrganizationSetting): Label {
  return {
    id: setting.id,
    name: setting.name,
    color: setting.data.color,
  };
}
