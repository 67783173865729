import { roundForGraph } from '../../../shared/utils/rounding';
import { TooltipFormatterCallbackFunction } from 'highcharts';

export const heatmapTooltipFormatter: (valueLabel: string) => TooltipFormatterCallbackFunction = (
  valueLabel: string,
) =>
  function () {
    const x = `${this.series.xAxis.options.title.text}: ${
      this.series.chart.xAxis[0].categories[this.point.x]
    }`;
    const y = `${this.series.yAxis.options.title.text}: ${
      this.series.chart.yAxis[0].categories[this.point.y]
    }`;
    const value = `${valueLabel}: ${roundForGraph(this.point.value)} (3 s.f.)`;
    return `${x}</br>${y}</br>${value}`;
  };
