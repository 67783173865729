import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  documentTableEditsFeatureKey,
  DocumentTableEditsState,
  Edit,
  EditMetadata,
  getCellID,
  StoredBulkEdit,
  StoredCellEdit,
} from './document-table-edits.reducer';

export const selectDocumentTableEditsState = createFeatureSelector<DocumentTableEditsState>(
  documentTableEditsFeatureKey,
);

export const selectDocumentIDsWithEdits = createSelector(selectDocumentTableEditsState, (state) =>
  Object.keys(state),
);

export const selectEditsForDocument = (documentID: string) =>
  createSelector(selectDocumentTableEditsState, (state) => state[documentID]);

export const selectEditsForDocumentTable = (documentID: string, tableName: string) =>
  createSelector(selectEditsForDocument(documentID), (documentState) =>
    documentState ? documentState[tableName] : undefined,
  );

export const selectCellEditsForDocumentTable = (documentID: string, tableName: string) =>
  createSelector(
    selectEditsForDocumentTable(documentID, tableName),
    (tableState) => tableState?.cellEdits,
  );

export const selectBulkEditsForDocumentTable = (documentID: string, tableName: string) =>
  createSelector(
    selectEditsForDocumentTable(documentID, tableName),
    (tableState) => tableState?.bulkEdits,
  );

export const selectBulkEditInProgressForDocumentTable = (documentID: string, tableName: string) =>
  createSelector(
    selectBulkEditsForDocumentTable(documentID, tableName),
    (bulkEdits) => bulkEdits && bulkEdits.some((bulkEdit) => bulkEdit.status !== 'completed'),
  );

export function selectEditsForCell<T = unknown>(
  documentID: string,
  tableName: string,
  row: number,
  column: string,
) {
  return createSelector(selectCellEditsForDocumentTable(documentID, tableName), (cellEdits) =>
    cellEdits
      ? (cellEdits[getCellID({ row, column })] as StoredCellEdit<T> | undefined)
      : undefined,
  );
}

function applyEditToListSetValue<T>(value: T[], edit: Edit<T[]>) {
  if (!edit) {
    return value;
  }
  // Replace Value edit?
  if ('value' in edit) {
    return edit.value;
  }
  // Add Value Edit?
  if ('add' in edit) {
    return Array.from(new Set(value.concat(edit.add))).sort();
  }
  console.error('Unrecognized edit type', value);
  return value;
}

type StoredEdit<T = unknown> = EditMetadata & { edit: Edit<T> };

function applyStoredEditsToListSetValue<T = unknown>(value: T[], ...edits: StoredEdit<T[]>[]) {
  const editsToApply = edits.sort((a, b) => a.editTimestamp - b.editTimestamp);
  if (editsToApply.length === 0) {
    return value;
  }
  return editsToApply.reduce(
    (state, storedEdit) => applyEditToListSetValue(state, storedEdit.edit),
    value,
  );
}

export function selectEditedStateForListSetCell<T = unknown>(
  documentID: string,
  tableName: string,
  row: number,
  column: string,
  serverState: T[],
) {
  return createSelector(
    selectEditsForCell<T[]>(documentID, tableName, row, column),
    selectBulkEditsForDocumentTable(documentID, tableName),
    (cellEdit, bulkEdits = []) => {
      const editsToApply: StoredEdit<T[]>[] = cellEdit ? [cellEdit] : [];
      const bulkEditsToApply = bulkEdits.filter(
        (bulkEdit) =>
          bulkEdit.column === column &&
          // selectAll means the row selection should be inverted
          bulkEdit.rows.includes(row) !== bulkEdit.selectAll,
      ) as StoredBulkEdit<T[]>[];
      return applyStoredEditsToListSetValue(serverState, ...editsToApply.concat(bulkEditsToApply));
    },
  );
}
