import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'bx-label-badge',
  templateUrl: './label-badge.component.html',
  styleUrls: ['./label-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle],
})
export class LabelBadgeComponent {
  /** When true, the remove button will not be shown */
  @Input() readonly = false;
  @Input() labelName: string;
  @Input() colorHex: string;
  /**
   * Fired when the user clicks the 'x' to close (remove) the label. The event contains the label's name.
   *
   * This is a presentational component and hence the parent handles the logic of adding/removing of labels, therefore it is the role of
   * onRemove to inform the parent that this label is to be removed.
   */
  @Output() removeLabel: Subject<string> = new EventEmitter<string>();

  onRemoveLabel(): void {
    this.removeLabel.next(this.labelName);
  }
}
