<span class="badge" [ngStyle]="{ 'background-color': colorHex }">
  <span class="invert-filter" [ngStyle]="{ color: colorHex }">{{ labelName }}</span>
  @if (!readonly) {
    <span
      (click)="onRemoveLabel()"
      title="Remove label"
      role="button"
      [ngStyle]="{ color: colorHex }"
      class="invert-filter ps-1"
      aria-hidden="true"
    >
      &times;
    </span>
  }
</span>
