import { Injectable } from '@angular/core';
import {
  DocumentDatasourceParams,
  DocumentServiceResource,
} from '../../../nucleus/services/documentService/document-service.resource';
import { Observable } from 'rxjs';
import { IGetRowsRequestMinimal } from '../grid/datasource/grid.resource';
import { map, shareReplay, take } from 'rxjs/operators';
import { IGridResourceResponse } from '../../../nucleus/services/models/response.model';
import { GraphUtilService } from './graph-util.service';
import { GraphTitleAndAxes } from './graph-sidebar';

@Injectable({
  providedIn: 'root',
})
export class GeneCombinationsHeatmapService {
  heatmapData$: Observable<RowWithGene[]>;
  private lastDataCallOptions: DataParameters;

  constructor(private documentServiceResource: DocumentServiceResource) {}

  /**
   * Recreates the heatmap data observable with new parameters. Should only be called when we can't
   * re-use data from the the previous data request.
   *
   * @param documentID
   * @param table
   * @param name
   * @private
   */
  private recreateHeatMapDataObservable$(parameters: DataParameters) {
    this.heatmapData$ = this.fetchDataFromChainCombinationsTable(
      parameters.documentID,
      parameters.table,
      parameters.names,
    ).pipe(
      map(this.parseChainCombinationsDataIntoHeatmap),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );
  }

  public getHeatmapDataFromChainCombinations(
    documentID: string,
    table: string,
    geneCombination: string,
    reduced: boolean = false,
  ): Observable<RowWithGene[]> {
    const newOptions = {
      documentID,
      table,
      names: this.splitColumnNameOn(geneCombination, 'Heavy-Light', '-'),
    };
    if (this.optionValuesRequiresNewData(this.lastDataCallOptions, newOptions)) {
      this.recreateHeatMapDataObservable$(newOptions);
    }

    this.lastDataCallOptions = {
      documentID,
      table,
      names: this.splitColumnNameOn(geneCombination, 'Heavy-Light', '-'),
    };

    // Because of the way this is used as a promise, changes don't emit new events, instead changes
    // re call this method, resubscribe and expect a single value to be emmited after subscription.
    return this.heatmapData$.pipe(
      map((data) => {
        if (reduced) {
          return GeneCombinationsHeatmapService.collapseHeatmapData(data);
        }
        return data;
      }),
      take(1),
    );
  }

  private optionValuesRequiresNewData(
    oldOptions: DataParameters,
    newOptions: DataParameters,
  ): boolean {
    if (!oldOptions) {
      return true;
    }
    return (
      oldOptions.documentID !== newOptions.documentID ||
      oldOptions.table !== newOptions.table ||
      oldOptions.names.toString() !== newOptions.names.toString()
    );
  }

  /**
   * Fetch two columns from the chain combinations table
   * @param documentID
   * @param table
   * @param names
   * @private
   */
  private fetchDataFromChainCombinationsTable(
    documentID: string,
    table: string,
    names: string[],
  ): Observable<Record<string, string>[]> {
    const datasourceParams = <DocumentDatasourceParams>{
      filterModel: `geneious_row_index BETWEEN 0 AND ${DocumentServiceResource.RESULT_SET_MAX}`,
      documentTableName: table,
      documentId: documentID,
    };

    const requestParams: IGetRowsRequestMinimal = {
      startRow: 0,
      endRow: DocumentServiceResource.RESULT_SET_MAX,
      sortModel: [],
      filterModel: null,
      fields: names,
    };
    return this.documentServiceResource
      .query(requestParams, datasourceParams, true)
      .pipe(map((data: IGridResourceResponse<Record<string, any>>) => data.data));
  }

  /**
   * Converts a pair of raw chain combinations columns into heatmap data
   *
   * @param rawData the values of some chain combinations columns
   * @private
   */
  private parseChainCombinationsDataIntoHeatmap(rawData: Record<string, string>[]): RowWithGene[] {
    const leftSet: Set<string> = new Set();
    const rightSet: Set<string> = new Set();
    const leftIndices: Record<string, number> = {};
    const splitRows: string[][][] = [];
    const heatmapData: RowWithGene[] = [];

    // parse raw chain combinations strings for each pair and generate lists of all gene pairs
    for (let row of rawData) {
      const splitRow: string[][] = [];
      const rowKeys = Object.keys(row);
      rowKeys.sort();
      const [leftCol, rightCol] = rowKeys;
      for (let left of row[leftCol].split(';')) {
        for (let right of row[rightCol].split(';')) {
          splitRow.push([left, right]);
        }
      }
      splitRows.push(splitRow);
    }

    // find all unique left/right genes
    for (let listOfPairs of splitRows) {
      for (let [left, right] of listOfPairs) {
        leftSet.add(left);
        rightSet.add(right);
      }
    }

    // convert them into sorted lists
    const lefts = [...leftSet];
    const rights = [...rightSet];
    lefts.sort(GraphUtilService.getCompareNaturalSort());
    rights.sort(GraphUtilService.getCompareNaturalSort());

    // initialise the gene combination counts
    let ix = 0;
    for (let left of lefts) {
      leftIndices[left] = ix;
      const leftRow: RowWithGene = <RowWithGene>{ Gene: left };
      for (let right of rights) {
        leftRow[right] = 0;
      }
      heatmapData.push(leftRow);
      ix++;
    }

    // record gene combination counts
    for (let listOfPairs of splitRows) {
      let weight = listOfPairs.length;
      for (let [left, right] of listOfPairs) {
        let leftIx = leftIndices[left];
        heatmapData[leftIx][right] += 1 / weight;
      }
    }
    return heatmapData;
  }

  static collapseHeatmapData(data: RowWithGene[]): RowWithGene[] {
    const allHeavy = [...new Set(data.map((d) => d.Gene).map(geneNameToGeneFamilyName))];
    const allLight = [
      ...new Set(
        data.flatMap((d) => Object.values(this.filterGene(d))).map(geneNameToGeneFamilyName),
      ),
    ];
    allHeavy.sort(GraphUtilService.getCompareNaturalSort());
    allLight.sort(GraphUtilService.getCompareNaturalSort());
    const combinations: Record<string, Record<string, number>> = {};
    for (const heavy of allHeavy) {
      combinations[heavy] = {};
      for (const light of allLight) {
        combinations[heavy][light] = 0;
      }
    }
    for (const gene of data) {
      const heavy = geneNameToGeneFamilyName(gene.Gene);
      for (const light of allLight) {
        const lightSum: number = Object.keys(gene)
          .filter((x) => geneNameToGeneFamilyName(x) === light)
          .map((key) => gene[key])
          .reduce((x, y) => x + y, 0);
        combinations[heavy][light] += lightSum;
      }
    }
    const geneList: RowWithGene[] = [];
    for (const heavy of allHeavy) {
      const toAppend = { Gene: heavy } as RowWithGene;
      for (const light of allLight) {
        toAppend[light] = combinations[heavy][light];
      }
      geneList.push(toAppend);
    }
    return geneList;
  }

  /**
   * Maps a string like "Heavy-Light V Gene" to a list of strings like ["Heavy V Gene", "Light V Gene"],
   * or a string like "Heavy VJ Gene" to a list of strings like "Heavy V Gene", "Heavy J Gene".
   *
   * @param name the string to split
   * @param variants the substring that determines the string "variants" (e.g. "Heavy-Light")
   * @param separator the separator within the variants substring (e.g. "-" for "Heavy-Light")
   * @private
   */
  private splitColumnNameOn(name: string, variants: string, separator: string): string[] {
    const splitters = variants.split(separator);
    const splitNames = [];
    for (let splitter of splitters) {
      splitNames.push(name.replace(variants, splitter));
    }
    return splitNames;
  }

  static getGeneCombinationTitleAndAxes(name: string, swap: boolean = false): GraphTitleAndAxes {
    const title = `Gene Combinations (${name})`;
    const { xAxisTitle, yAxisTitle } = this.splitGeneCombinationIntoAxesTitles(name);
    return {
      title,
      xAxisTitle: swap ? yAxisTitle : xAxisTitle,
      yAxisTitle: swap ? xAxisTitle : yAxisTitle,
    };
  }

  private static splitGeneCombinationIntoAxesTitles(name: string): {
    xAxisTitle: string;
    yAxisTitle: string;
  } {
    // If a chain prefix is present, we can remove it and process the name as if it weren't there
    // todo: check if this assumption will always be true
    let prefix = '';
    if (name.includes(': ')) {
      [prefix, name] = name.split(': ');
      prefix = `${prefix}: `;
    }
    // Gene combination name will be in then format of 'Heavy VJ Gene' or 'Heavy-Light J Gene'.
    // Either Heavy and Light for the same region or 2 different regions on the same chain.
    const [chain, region] = name.split(' ');
    const chains = chain.split('-');
    const regions = region.split('');

    if (chains.length === 2) {
      return {
        xAxisTitle: `${prefix}${chains[0]} ${region} Gene`,
        yAxisTitle: `${prefix}${chains[1]} ${region} Gene`,
      };
    } else if (regions.length === 2) {
      return {
        xAxisTitle: `${prefix}${chain} ${regions[0]} Gene`,
        yAxisTitle: `${prefix}${chain} ${regions[1]} Gene`,
      };
    } else {
      return {
        xAxisTitle: '',
        yAxisTitle: '',
      };
    }
  }

  static filterGene(data: RowWithGene) {
    return Object.keys(data).filter((key) => key !== 'Gene');
  }
}

type DataParameters = { documentID: string; table: string; names: string[] };

export function parseGeneName(gene: string): {
  geneName: string;
  geneFamilyName: string;
  dbName: string | null;
} {
  const dbSuffixRegex = /([^\[\]]*?)\s+\[([^\[\]]*)\]/;
  const geneFamilyNameSeparatorRegex = /[-/*S]/;
  let unparsedGeneName = gene.trim();
  const matches = gene.match(dbSuffixRegex);
  let dbName = matches?.length > 2 ? matches[2] : null;
  const prefixWithDbName = (name: string): string =>
    dbName === null ? name : `${dbName}: ${name}`;
  const stripDbNameSuffix = (name: string): string =>
    dbName === null ? name : name.replace(` [${dbName}]`, '');

  unparsedGeneName = stripDbNameSuffix(unparsedGeneName);
  const geneFamilyName = prefixWithDbName(unparsedGeneName.split(geneFamilyNameSeparatorRegex)[0]);
  const geneName = prefixWithDbName(unparsedGeneName);
  return {
    geneName,
    geneFamilyName,
    dbName,
  };
}
export function geneNameToGeneFamilyName(gene: string) {
  return parseGeneName(gene).geneFamilyName;
}

export function geneNameWithDbPrefix(gene: string) {
  return parseGeneName(gene).geneName;
}

export type RowWithGene = {
  Gene: string;
} & {
  [key: string]: number;
};
