import { Injectable } from '@angular/core';
import { HeatmapData } from './graph-heatmap/graph-heatmap.component';
import { ColDef } from '@ag-grid-community/core';
import { isNumber, PointOptionsObject } from 'highcharts';

@Injectable({
  providedIn: 'root',
})
export class GraphUtilService {
  constructor() {}

  /**
   * Converts plain rows to a format that can be used with ag-grid. Computes Column Defs.
   *
   * @param rows
   * @param {string} sortColumn Column to sort by default.
   * @param {string} colSortFunction Column to sort by default.
   * @returns {GridData}
   */
  public static rowsToTable(
    rows: any[],
    sortColumn: string,
    colSortFunction: (a: any, b: any) => number = this.getCompareNaturalSort(),
  ): GridData {
    if (rows && rows.length && Object.keys(rows[0]).length) {
      const colNames = Object.keys(rows[0]);
      const cols = colNames.sort(colSortFunction).map((header: string) => {
        const formatted: any = {
          field: header,
          headerName: header,
          // Natural sort as default.
          comparator: this.getCompareNaturalSort(),
        };
        if (header === sortColumn) {
          formatted.sort = 'asc';
        }
        return formatted;
      });
      return { cols, rows };
    } else {
      return { cols: [], rows: [] };
    }
  }

  // TODO Deduplicate from NgsReportService.transformHeatmapData().
  /**
   * Converts to Heatmap format, where the parameters of each row are represented as Columns in the heatmap.
   * The row values must be able to be parsed to integer, unless their row label is listed in the excluded columns.
   * The rows are assumed to be in the desired order already. Columns will be string sorted.
   *
   * @param data An array of row objects
   * @param {string[]} excludeKeys Parameters from each row that should be ignored - these columns will not appear in the heatmap.
   * @param rowNameKey Parameter in each row that contains the "label" for that row. Labels will be shown on the y axis.
   * @param options Optional boolean parameters that affect the overall presentation of heatmap.
   *                hasCodonCols, hasCodonRows - Format the axis labels as if they were codons.
   *                isPercent: Tells the heatmap that the values are between 0 and 1 and represent percentages.
   *
   * @returns {HeatmapData}
   */
  public static rowsToHeatmap(
    data: { [key: string]: string }[],
    excludeKeys: string[],
    rowNameKey: string,
    options: any,
  ): HeatmapData {
    const includeParameter = (maybeParameter: any) =>
      !(excludeKeys.includes(maybeParameter) || rowNameKey === maybeParameter);
    const maybeCodon = (label: string, shouldCapitalise: boolean) =>
      shouldCapitalise ? this.formatCodon(label) : label.toString();

    if (data && data.length) {
      const columnNames = Object.keys(data[0]).filter((key) => includeParameter(key));
      if (options.hasCodonCols) {
        columnNames.sort(this.compareMaybeCodons);
      } else {
        columnNames.sort(this.getCompareNaturalSort());
      }

      if (columnNames.length) {
        const rows = data
          .filter((row: any) => row[rowNameKey] !== null)
          .map((row: any) => row[rowNameKey]);

        if (options.hasCodonRows) {
          rows.sort(this.compareMaybeCodons).reverse();
        } else {
          rows.sort(this.getCompareNaturalSort()).reverse();
        }

        const labels = {
          x: columnNames.map((colName) => maybeCodon(colName, options.hasCodonCols)),
          y: rows.map((rowName: string) => maybeCodon(rowName, options.hasCodonRows)),
        };

        const formattedData = data
          .filter((row) => row[rowNameKey] !== null)
          .map((row) => {
            const y = row[rowNameKey];
            return columnNames.map((col) => {
              return [
                labels.x.indexOf(maybeCodon(col, options.hasCodonCols)),
                labels.y.indexOf(maybeCodon(y, options.hasCodonRows)),
                // Handle missing values - a missing value is likely to represent 0
                this.parseValueToNumber(row[col]),
              ];
            });
          })
          .reduce((a, b) => a.concat(b), []);

        return {
          labels: labels,
          tooltipLabel: 'Frequency',
          series: { type: 'heatmap', data: formattedData },
        };
      }
    }

    return {
      labels: {
        x: [] as string[],
        y: [] as string[],
      },
      tooltipLabel: '',
      series: { type: 'heatmap', data: [] },
    };
  }

  /**
   * Flips row data so that the old columns are now rows.
   *
   * @param {any[]} rows
   * @param {string} newHeaderColumn Flip using this row as the pivot. The values of this column will now become the column header names.
   * @param headerLabel The name to use for the new column created out of the original column headers.
   * @param {string[]} ignoreColumns Columns to leave out of the data (will not become rows).
   * @returns {any[]}
   */
  public static transposeDataList(
    rows: any[],
    newHeaderColumn: string,
    headerLabel: string,
    ignoreColumns: string[],
  ) {
    if (!rows || !rows.length) {
      return [];
    }
    return Object.keys(rows[0])
      .filter((key) => key !== newHeaderColumn && !ignoreColumns.includes(key))
      .map((key) => {
        return rows.reduce((acc, newRow) => {
          acc[newRow[newHeaderColumn]] = newRow[key];
          acc[headerLabel] = key;
          return acc;
        }, {});
      });
  }

  public static getFormattedAminoAcid(aminoAcid: string): string {
    const uppercaseAA = aminoAcid.toUpperCase();
    return AminoAcidsEnum[uppercaseAA as keyof typeof AminoAcidsEnum]
      ? `${uppercaseAA} (${AminoAcidsEnum[uppercaseAA as keyof typeof AminoAcidsEnum]})`
      : aminoAcid;
  }

  public static rowsToPercentage(
    rows: any[],
    keysToIgnore: any,
    totalKey: any,
    multiplier: number,
    roundBy: number,
  ) {
    multiplier = multiplier || 100;
    return rows.map((row) => {
      Object.keys(row)
        .filter((key) => !keysToIgnore.includes(key) && totalKey !== key)
        .forEach((key) => {
          const percentage =
            row[totalKey] > 0 ? (parseInt(row[key]) / row[totalKey]) * multiplier : 0;
          row[key] = +percentage.toFixed(roundBy);
        });
      return row;
    });
  }

  static getPointY(point: number | [string | number, number] | PointOptionsObject): number {
    if (isNumber(point)) {
      return Number(point);
    }
    if (Array.isArray(point)) {
      return point[1];
    } else if ((point as PointOptionsObject).y !== undefined) {
      return (point as PointOptionsObject).y;
    }
  }

  static getPointX(
    point: number | [string | number, number] | PointOptionsObject,
  ): string | number {
    if (isNumber(point)) {
      return Number(point);
    }
    if (Array.isArray(point)) {
      return point[0];
    } else if ((point as PointOptionsObject).x !== undefined) {
      return (point as PointOptionsObject).x;
    }
  }

  private static compareMaybeCodons(a: any, b: any): number {
    const residueA = StandardCodonsEnum[a.toUpperCase() as keyof typeof StandardCodonsEnum];
    const residueB = StandardCodonsEnum[b.toUpperCase() as keyof typeof StandardCodonsEnum];
    if (residueA != null && residueB != null) {
      if (residueA !== residueB) {
        return residueA < residueB ? -1 : +1;
      } else {
        return a.toUpperCase < b.toUpperCase ? -1 : +1;
      }
    } else if (residueA != null) {
      return +1;
    } else if (residueB != null) {
      return -1;
    } else {
      return a < b ? -1 : +1;
    }
  }

  /**
   * Returns a function that performs natural sort.
   *
   * @returns {Function}
   */
  public static getCompareNaturalSort() {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    return function (valueA: any, valueB: any) {
      return collator.compare(valueA, valueB);
    };
  }

  /**
   * Force some values to always be sorted first, in a specific order.
   * @param fixed Parameters to fix position of e.g {AMINO: 1, CODON: 2};
   * @returns {function} Comparison function
   */
  public static getCompareWithFixed(fixed: any) {
    return (a: any, b: any) => {
      if (fixed[a] && fixed[b]) {
        return fixed[a] < fixed[b] ? -1 : +1;
      } else if (fixed[a]) {
        return -1;
      } else if (fixed[b]) {
        return +1;
      } else {
        return GraphUtilService.getCompareNaturalSort()(a, b);
      }
    };
  }

  // Convert values to a non-negative number for use in a heatmap.
  private static parseValueToNumber(value: any) {
    if (value === null || value === undefined) {
      // Handle missing values - a missing value is likely to represent 0
      return 0;
    } else if (!isNaN(parseFloat(value))) {
      // It is a number or can be converted to one.
      return this.convertToPositive(parseFloat(value));
    } else {
      console.log(
        'Warning: Could not convert heatmap value ' + value + 'to a number. Assigning it to 0.',
      );
      return 0;
    }
  }

  private static convertToPositive(value: any) {
    const isPositive = value >= 0;
    if (!isPositive) {
      console.log('Warning: Converting negative heatmap value ' + value + 'to 0');
    }
    return isPositive ? value : 0;
  }

  public static formatCodon(codon: string): string {
    const uppercase = codon.toUpperCase();
    return StandardCodonsEnum[uppercase as keyof typeof StandardCodonsEnum]
      ? `${uppercase} (${StandardCodonsEnum[uppercase as keyof typeof StandardCodonsEnum]})`
      : codon;
  }
}

export enum AminoAcidsEnum {
  A = 'Ala',
  C = 'Cys',
  D = 'Asp',
  E = 'Glu',
  F = 'Phe',
  G = 'Gly',
  H = 'His',
  I = 'Ile',
  K = 'Lys',
  L = 'Leu',
  M = 'Met',
  N = 'Asn',
  P = 'Pro',
  Q = 'Gln',
  R = 'Arg',
  S = 'Ser',
  T = 'Thr',
  V = 'Val',
  W = 'Trp',
  Y = 'Tyr',
}

// Amino Acids from codons using standard translation table.
// TODO Support other tables.
export enum StandardCodonsEnum {
  TAA = '*',
  TAG = '*',
  TGA = '*',
  GCA = 'A',
  GCC = 'A',
  GCG = 'A',
  GCT = 'A',
  TGC = 'C',
  TGT = 'C',
  GAC = 'D',
  GAT = 'D',
  GAA = 'E',
  GAG = 'E',
  TTC = 'F',
  TTT = 'F',
  GGA = 'G',
  GGC = 'G',
  GGG = 'G',
  GGT = 'G',
  CAC = 'H',
  CAT = 'H',
  ATA = 'I',
  ATC = 'I',
  ATT = 'I',
  AAA = 'K',
  AAG = 'K',
  CTA = 'L',
  CTC = 'L',
  CTG = 'L',
  CTT = 'L',
  TTA = 'L',
  TTG = 'L',
  ATG = 'M',
  AAC = 'N',
  AAT = 'N',
  CCA = 'P',
  CCC = 'P',
  CCG = 'P',
  CCT = 'P',
  CAA = 'Q',
  CAG = 'Q',
  AGA = 'R',
  AGG = 'R',
  CGA = 'R',
  CGC = 'R',
  CGG = 'R',
  CGT = 'R',
  AGC = 'S',
  AGT = 'S',
  TCA = 'S',
  TCC = 'S',
  TCG = 'S',
  TCT = 'S',
  ACA = 'T',
  ACC = 'T',
  ACG = 'T',
  ACT = 'T',
  GTA = 'V',
  GTC = 'V',
  GTG = 'V',
  GTT = 'V',
  TGG = 'W',
  TAC = 'Y',
  TAT = 'Y',
}

export interface GridData {
  cols: ColDef[];
  rows: any[];
}
