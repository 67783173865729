import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { map, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { Label } from '../../label.model';
import { LabelPickerComponentStore, PickableLabel } from '../label-picker-store.service';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { NgStyle, AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbHighlight } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-label-single-select',
  templateUrl: './label-single-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LabelPickerComponentStore],
  standalone: true,
  imports: [FaIconComponent, NgStyle, NgbHighlight, AsyncPipe],
})
export class LabelSingleSelectComponent extends CleanUp implements OnInit, AfterViewInit {
  @HostBinding('class') readonly hostClass = 'd-block';
  /** IDs of labels that are already selected. These will be omitted from the list of selectable labels. */
  @Input() initialSelection: string[];
  @Output() labelSelected: Subject<Label> = new EventEmitter<Label>();
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  readonly swatchIcon = faCircle;

  /**
   * Labels to display in the list, filtered by the search query. Labels that
   * are already selected are not ever shown.
   */
  readonly displayedLabels$ = this.labelStore.displayedLabels$.pipe(
    withLatestFrom(
      this.labelStore.selectedLabels$.pipe(map((labels) => labels.map((label) => label.id))),
    ),
    map(([displayedLabels, selectedIDs]) =>
      displayedLabels.filter((label) => !selectedIDs.includes(label.id)),
    ),
  );

  constructor(private readonly labelStore: LabelPickerComponentStore) {
    super();
  }

  ngOnInit(): void {
    // Initialize store with existing selected labels
    this.labelStore.setSelected({ ids: this.initialSelection ?? [], selected: true });
    // Update store when labelSelected emits (just in case store is ever exposed)
    this.labelSelected
      .pipe(
        withLatestFrom(this.labelStore.selectedLabels$),
        map(([label, selectedLabels]) => [...selectedLabels, label].map((label) => label.id)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((ids) => this.labelStore.setSelected({ ids, selected: true }));
  }

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.focus();
  }

  search(term: string): void {
    this.labelStore.filterDisplayed(term);
  }

  selectLabel(storeLabel: PickableLabel) {
    const { selected, displayed, ...label } = storeLabel;
    this.labelSelected.next(label);
  }
}
