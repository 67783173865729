<div class="mb-2">
  <input
    #searchInput
    type="text"
    title="Search labels"
    class="form-control"
    placeholder="Search labels..."
    (input)="search($event.target.value)"
    data-spec="label-search"
  />
</div>
@if (displayedLabels$ | async; as displayedLabels) {
  <div class="h-static-1 overflow-y-auto d-block mb-2">
    @if (displayedLabels.length > 0) {
      <div class="list-group list-group-flush pe-2">
        @for (label of displayedLabels; track label) {
          <button
            class="d-flex align-items-center border-0 my-0 py-1 px-2 list-group-item list-group-item-action"
            (click)="selectLabel(label)"
            data-spec="label"
          >
            <fa-icon
              [icon]="swatchIcon"
              [ngStyle]="{ color: label.color }"
              class="me-2"
              data-spec="label-swatch"
            ></fa-icon>
            <ngb-highlight
              [result]="label.name"
              [term]="searchInput.value"
              class="text-truncate"
              [title]="label.name"
              data-spec="label-name"
            ></ngb-highlight>
          </button>
        }
      </div>
    } @else {
      <span class="text-muted p-2 d-block w-100 text-center" data-spec="no-matching-labels">
        No matching labels
      </span>
    }
  </div>
}
