/**
 * Rounds to 3 s.f.
 */
export function roundForGraph(value: number | string): number {
  return round(value, 3);
}

/**
 * Rounds to 4 s.f.
 */
export function roundForTable(value: number | string): number {
  return round(value, 4);
}

/**
 * Rounds to specified significant figures without trailing zeroes.
 */
function round(value: number | string, sf: number): number {
  return Number(parseFloat(value + '').toPrecision(sf));
}
