import { EMPTY, forkJoin, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, first, map, switchMap, take, tap } from 'rxjs/operators';
import { AbstractColDef, ColDef, ColGroupDef } from '@ag-grid-community/core';
import { Injectable, OnDestroy } from '@angular/core';
import {
  IGetRowsRequestMinimal,
  IGridResource,
} from '../../../app/features/grid/datasource/grid.resource';
import { DocumentServiceDocumentQuery, OrderBy } from './document-service.v1.http';
import {
  DocumentServiceColumn,
  DocumentServiceColumnDataType,
  DocumentServiceTableInfo,
  DocumentServiceTableInfoJoin,
  DocumentServiceTableInfoMap,
} from './types';
import { orderByType } from './document-service.v1';
import { BxHttpError } from '../../../app/core/BxHttpError';
import { IGridResourceResponse } from '../models/response.model';
import { SortModel } from '../../../app/features/grid/grid.interfaces';
import { getIndicesOf, insertAt, replaceAll } from '../../../app/shared/string-util';
import { DataManagementService } from '@geneious/nucleus-api-client';
import { AppState } from '../../../app/core/core.store';
import { selectNameSchemeByID } from '../../../app/core/organization-settings/organization-settings.selectors';
import { Store } from '@ngrx/store';
import { OrganizationSetting } from '../../../app/core/models/settings/setting.model';
import { getAllColumnsWithJoins } from '../../../app/core/document-table-service/document-table-columns/get-all-columns-with-joins';
import { DocumentTableType } from './document-table-type';
import { DocumentTableStateService } from '../../../app/core/document-table-service/document-table-state/document-table-state.service';
import { DocumentTableQueryService } from '../../../app/core/document-table-service/document-table-state/document-table-query.service';

export interface DocumentDatasourceParams {
  filterModel: string;
  documentTableName: string;
  documentId: string;
  // Used for forcing the label renderer to be readonly.
  readonly?: true;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentServiceResource implements OnDestroy, IGridResource {
  // Maximum amount of rows that are scrollable via this resource.
  static RESULT_SET_MAX = 10000;
  private currentColumnsWithJoins: DocumentServiceColumn[];
  private allTablesInfo: DocumentServiceTableInfoMap;
  private error$: ReplaySubject<any>;
  private cachedNameSchemeInfo: { [type: string]: OrganizationSetting } = {};
  private currentSortModel: SortModel[] = [];

  constructor(
    private documentTableQueryService: DocumentTableQueryService,
    private documentTableStateService: DocumentTableStateService,
    private dataManagementService: DataManagementService,
    private store: Store<AppState>,
  ) {
    this.error$ = new ReplaySubject<any>(1);
  }

  ngOnDestroy(): void {
    this.error$.complete();
  }

  onError(): Observable<any> {
    return this.error$.asObservable();
  }

  getCurrentSortModel(): SortModel[] {
    return this.currentSortModel;
  }

  getTableType(tableKey: string): DocumentTableType | undefined {
    return this.allTablesInfo[tableKey] ? this.allTablesInfo[tableKey].tableType : undefined;
  }

  /**
   * Get the Joined Column info of a given table.
   * It will also respond with the full table keys of the base table and related join table.
   *
   * @param tableKey - key name of the table. This is usually prefixed with a string, e.g.
   *     `ASSAY_DATA_`. The tableKey can only consist of other nested table keys separated by a
   *     colon, e.g. `ASSAY_DATA_Assay 1:ASSAY_DATA_Assay 2`.
   */
  getJoinedColumnInfo(
    tableKey: string,
  ):
    | { baseTableKey: string; joinTableKey: string; joinInfo: DocumentServiceTableInfoJoin }
    | undefined {
    const leafTableKey = tableKey.includes(':') ? tableKey.split(':').pop() : tableKey;
    const baseTableKey = Object.keys(this.allTablesInfo).find(
      (key) => this.allTablesInfo[key].joins[leafTableKey],
    );
    const fullBaseTableKey = this.getFullTableKey(baseTableKey, this.allTablesInfo);
    const fullJoinTableKey = this.getFullTableKey(leafTableKey, this.allTablesInfo);
    const tableInfo = this.allTablesInfo[baseTableKey];
    if (tableInfo) {
      return {
        baseTableKey: fullBaseTableKey,
        joinTableKey: fullJoinTableKey,
        joinInfo: tableInfo.joins[leafTableKey],
      };
    }
  }

  /**
   * Query table data
   * @param params row parameters
   * @param datasourceParams data source parameters
   * @param includeAll When true(BEWARE), limit, size and offset parameters of params and
   *     datasourceParams will be ignored and all the data will be retrieved iteratively using the
   *     query/cursor endpoints. This could be extremely slow for larger data sets. Only
   *     recommended for the cases where entire data set is required such as graphs. When false or
   *     undefined data will be paged honoring the limit, size and offset parameters of params and
   *     datasourceParams
   * @param overrideParams if specified, replaces the default DocumentServiceDocumentQuery.
   */
  query(
    params: IGetRowsRequestMinimal,
    datasourceParams: DocumentDatasourceParams,
    includeAll?: boolean,
    overrideParams: DocumentServiceDocumentQuery = {},
  ): Observable<IGridResourceResponse<any>> {
    this.error$.next(undefined);
    this.currentSortModel = params.sortModel;

    const tables$ = this.documentTableStateService
      .getTablesMap(datasourceParams.documentId)
      .pipe(take(1));

    return tables$.pipe(
      switchMap((tableInfo) => {
        if (tableInfo[datasourceParams.documentTableName].indexState === 'absent') {
          this.error$.next({
            pageMessage: `<h5>Couldn't load table</h5><br/><span>Please contact support.</span>`,
          });
          return EMPTY;
        } else {
          return of(tableInfo);
        }
      }),
      switchMap((tableInfo) => {
        // Save column names and types for reference when parsing filters.
        this.setCurrentColumns(tableInfo[datasourceParams.documentTableName]);
        this.allTablesInfo = tableInfo;

        const options: DocumentServiceDocumentQuery = {
          offset: params.startRow,
          limit: params.endRow - params.startRow,
          orderBy: this.getOrderByFromSortModel(
            params.sortModel,
            datasourceParams.documentTableName,
            tableInfo,
          ),
          where: DocumentServiceResource.parseFilterModel(
            datasourceParams.filterModel,
            this.currentColumnsWithJoins,
          ),
          fields: params.fields,
          ...overrideParams,
        };

        return this.queryTableWithJoins(
          datasourceParams.documentId,
          datasourceParams.documentTableName,
          tableInfo,
          options,
          datasourceParams.readonly,
          includeAll,
        ).pipe(
          // Retry once with no filter/sorting if the exception is caused by possible bad column sorting or filter.
          catchError((error: BxHttpError) => {
            if (DocumentServiceResource.sortedOnEmptyColumn(error)) {
              // In that case we need to remove the sort.
              const retryOptions = Object.assign({}, options);
              retryOptions.orderBy = [];
              return this.queryTableWithJoins(
                datasourceParams.documentId,
                datasourceParams.documentTableName,
                tableInfo,
                retryOptions,
                datasourceParams.readonly,
                includeAll,
              );
            } else {
              return throwError(error);
            }
          }),
          // Update saved columns by adding join table columns.
          tap((response) => {
            this.currentColumnsWithJoins = parseToDocumentServiceColumns(response.columns);
          }),
        );
      }),
      catchError((error) => {
        this.error$.next(error);
        return throwError(error);
      }),
    );
  }

  /**
   * Query data for both the main document table and the joined tables and combine them together.
   *
   * @param documentID string id of the document.
   * @param documentTable string id of the document table to query.
   * @param tableInfo Map of all Document Tables keyed by table name.
   * @param options DocumentServiceDocumentQuery
   * @param readonly Force cell renderers to be readonly
   * @param includeAll When true(BEWARE), limit, size and offset option parameters will be ignored
   *     and all the data will be retrieved iteratively using the query/cursor endpoints. This
   *     could be extremely slow for larger data sets. Only recommended for the cases where entire
   *     data set is required such as graphs. When false or undefined data will be paged honoring
   *     the limit, size and offset option parameters.
   */
  public queryTableWithJoins(
    documentID: string,
    documentTable: string,
    tableInfo: DocumentServiceTableInfoMap,
    options: DocumentServiceDocumentQuery,
    readonly?: boolean,
    includeAll?: boolean,
  ): Observable<IGridResourceResponse<any>> {
    const query$ = this.documentTableQueryService.queryTable(
      documentID,
      documentTable,
      options,
      includeAll,
    );

    // Check whether the selected document has a name scheme associated and fetch information for it if so.
    const nameScheme$ = this.getNameScheme(documentID);

    return forkJoin([query$, nameScheme$]).pipe(
      map(([response, nameScheme]) => {
        const columns = getAllColumnsWithJoins(documentTable, tableInfo, nameScheme, readonly);

        return {
          data: response.data,
          columns: columns,
          metadata: {
            offset: response.metadata.offset,
            limit: response.metadata.limit,
            // Limit amount of rows displayable to the RESULT_SET_MAX.
            total: response.metadata.total,
          },
        };
      }),
    );
  }

  // Get orderBy by parsing existing sortModel into document table service's sortModel by appending
  // `.keyword` to certain column names.
  public getOrderByFromSortModel(
    sortModel: SortModel[],
    documentTable: string,
    allTablesInfo: DocumentServiceTableInfoMap,
  ): OrderBy[] {
    return orderByType(sortModel, documentTable, allTablesInfo);
  }

  public setCurrentColumns(table: Pick<DocumentServiceTableInfo, 'columns'>) {
    this.currentColumnsWithJoins = table ? table.columns : [];
  }

  public getCurrentColumns(): DocumentServiceColumn[] {
    return this.currentColumnsWithJoins;
  }

  /**
   * Parses the filter model by replacing chars the user shouldn't have to understand.
   * Append .keyword to column names that are of either String OR Array type, as long as they
   * aren't involved in a null comparison clause. Replace `!=` with `<>` as the API doesn't
   * understand != syntax.
   *
   * TODO Addition of '.keyword' should ideally be handled by the Document Table Service backend.
   * This logic is brittle and complicated.
   *
   * @param filterModel
   * @param columns
   */
  public static parseFilterModel(filterModel: string, columns: DocumentServiceColumn[]): string {
    let newFilter = filterModel;
    // Wrap column names in quotes if they aren't already.
    columns.forEach((column) => {
      newFilter = replaceAll(newFilter, `[${column.name}]`, `['${column.name}']`);
    });

    // Find all column names and their position within the filter string.
    const columnAndPositions: {
      positions: number[];
      name: string;
      isStringOrArrayType: boolean;
    }[] = columns
      .map((column) => {
        const colPositions = getIndicesOf(`['${column.name}']`, newFilter, true)
          // Adjust to be the actual position of the column not surrounding brackets.
          .map((position) => position + 2);
        const isStringOrArrayType = [
          DocumentServiceColumnDataType.String,
          DocumentServiceColumnDataType.Array,
        ].includes(column.dataType.kind);
        return { name: column.name, positions: colPositions, isStringOrArrayType };
      })
      .filter((columnAndPosition) => columnAndPosition.positions.length);

    // Find the positions of a null comparison, if any.
    // TODO This should be handled on the backend - this logic will fail if the user's string non-null search term contains any of the
    // following null indicators, as this logic will assume that is a null clause.
    const nullPositions: number[] = [];
    const nullIndicators = [' is null', '=null', '= null', ' not null'];
    nullIndicators.forEach((nullIndicator) => {
      nullPositions.push(...getIndicesOf(nullIndicator, newFilter, false));
    });

    // Identify the columns involved in any null comparisons.
    const allColumnPositions: number[] = columnAndPositions
      .reduce((allCols, col) => allCols.concat(col.positions), [])
      .sort();
    const ignoreColumnPositions: number[] = [];
    nullPositions.forEach((nullPosition) => {
      // Column in a position directly before the null is the column involved in the null clause.
      const involvedColumnPosition = Math.max(
        ...allColumnPositions.filter((columnPosition) => columnPosition < nullPosition),
      );
      if (involvedColumnPosition) {
        ignoreColumnPositions.push(involvedColumnPosition);
      }
    });

    // Find the positions of the end of valid column names that aren't involved in a null comparison clause (see BX-3484 for detail).
    const positionsToInsertKeyword: number[] = [];
    columnAndPositions
      .filter((column) => column.isStringOrArrayType)
      .forEach((column) => {
        column.positions
          .filter((columnPosition) => !ignoreColumnPositions.includes(columnPosition))
          .forEach((validPosition) => {
            positionsToInsertKeyword.push(validPosition + column.name.length);
          });
      });
    // Iterate through the identified positions and insert required .keyword syntax.
    // Must iterate backwards so that remaining positions are still correct after .keyword is inserted.
    positionsToInsertKeyword.sort((a, b) => b - a);
    positionsToInsertKeyword.forEach((insertAtPosition) => {
      newFilter = insertAt(newFilter, insertAtPosition, '.keyword');
    });

    return newFilter.replace(/(\['.+?']\s*)!=/gm, '$1<>');
  }

  private getNameScheme(documentID: string): Observable<OrganizationSetting> {
    // Cache the name scheme info so it is not fetched for each page.
    if (this.cachedNameSchemeInfo[documentID]) {
      return of(this.cachedNameSchemeInfo[documentID]);
    } else {
      return this.dataManagementService.getDocument(documentID).pipe(
        map((result) => result.data.metadata.fileNameSchemeID),
        switchMap((fileNameSchemeID) =>
          fileNameSchemeID
            ? this.store.select(selectNameSchemeByID(fileNameSchemeID)).pipe(first())
            : of(null),
        ),
        tap((nameScheme) => (this.cachedNameSchemeInfo[documentID] = nameScheme)),
      );
    }
  }

  private getFullTableKey(tableKey: string, allTableInfo: DocumentServiceTableInfoMap): string {
    for (const key of Object.keys(allTableInfo)) {
      if (allTableInfo[key].joins[tableKey] && key !== 'DOCUMENT_TABLE_ALL_SEQUENCES') {
        return this.getFullTableKey(key, allTableInfo) + ':' + tableKey;
      }
    }

    return tableKey;
  }

  /**
   * If the error response has query_shard_exception, then it is strongly likely to be the cause of
   * sorting on an empty column.
   *
   * @param error {BxHttpError}
   */
  private static sortedOnEmptyColumn(error: BxHttpError) {
    return (
      error.status === 400 && error.error.detail === 'Bad request. Reason: query_shard_exception.'
    );
  }
}

export function parseToDocumentServiceColumns(columns: AbstractColDef[]): DocumentServiceColumn[] {
  return columns
    .map((col) => {
      if ('groupId' in col) {
        const group = col as ColGroupDef;
        return group.children.map((child) => generateDocumentServiceColumn(child));
      } else {
        const individual = col as ColDef;
        return [generateDocumentServiceColumn(individual)];
      }
    })
    .reduce((flattened, newColumns) => flattened.concat(newColumns), []);
}

// TODO Temporary for generateDocumentServiceColumn only.
interface ColDefWithMetadata extends ColDef {
  metadata?: { type: DocumentServiceColumnDataType };
}

// TODO Fix this HACK by instead removing the need of adding the property 'metadata' to ColDef or creating a proper child type of ColDef
//      in BX-5385
function generateDocumentServiceColumn(col: ColDefWithMetadata): DocumentServiceColumn {
  const type =
    'metadata' in col && col.metadata.type
      ? col.metadata.type
      : DocumentServiceColumnDataType.String;
  return {
    name: col.field,
    originalName: col.headerName,
    displayName: col.headerName,
    dataType: { kind: type },
  };
}

export interface CurrentQueryParameters {
  params: IGetRowsRequestMinimal;
  datasourceParams: DocumentDatasourceParams;
}
