import { Directive, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ChartComponent } from '../chart/chart.component';
import {
  AlignValue,
  ChartOptions,
  defaultOptions,
  ExportingOptions,
  LegendOptions,
  Options,
  OptionsLayoutValue,
  OptionsStackingValue,
  PlotOptions,
  SeriesOptions,
  SubtitleOptions,
  TitleOptions,
  TooltipOptions,
  VerticalAlignValue,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import { DownloadTableOptions, ExportableChart } from '../exportable-chart';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseChartComponent<T extends SeriesOptions = SeriesOptions>
  implements ExportableChart
{
  @HostBinding('class') readonly hostClass = 'd-flex h-100 w-100';
  @Input() set title(value: string) {
    this.titleOptions = {
      ...this.titleOptions,
      text: value,
    };
  }
  @Input() set subtitle(value: string) {
    this.subtitleOptions = {
      ...this.subtitleOptions,
      text: value,
    };
  }
  @Input() set xAxisTitle(value: string) {
    this.xAxisOptions = {
      ...this.xAxisOptions,
      title: {
        ...this.xAxisOptions?.title,
        text: value,
      },
    };
  }
  @Input() set yAxisTitle(value: string) {
    this.yAxisOptions = {
      ...this.yAxisOptions,
      title: {
        ...this.yAxisOptions?.title,
        text: value,
      },
    };
  }
  @Input() set showLegend(value: boolean) {
    this.legendOptions = {
      ...this.legendOptions,
      enabled: value,
    };
  }
  @Input() set yAxisRange(value: { min: number; max?: number; ceiling?: number } | undefined) {
    if (value) {
      this.yAxisOptions = {
        ...this.yAxisOptions,
        min: value.min,
        max: value.max,
        ceiling: value.ceiling,
      };
    } else {
      this.yAxisOptions = {
        ...this.yAxisOptions,
        min: null,
        max: null,
        ceiling: null,
      };
    }
  }
  @Input() set stacking(value: OptionsStackingValue | undefined) {
    this.plotOptions = {
      ...this.plotOptions,
      column: {
        ...this.plotOptions?.column,
        stacking: value,
      },
    };
  }
  @Input() set showDataLabels(value: boolean) {
    this.plotOptions = {
      ...this.plotOptions,
      column: {
        ...this.plotOptions?.column,
        dataLabels: {
          ...this.plotOptions?.column?.dataLabels,
          enabled: value,
        },
      },
    };
  }
  @Input() set data(value: T[]) {
    this.series = value;
    this.processedSeries = this.prepareSeries(this.series);
  }
  @Input() set animations(value: boolean) {
    this.chartOptions = {
      ...this.chartOptions,
      animation: value,
    };
    this.plotOptions = {
      ...this.plotOptions,
      series: {
        ...this.plotOptions?.series,
        animation: value,
      },
    };
  }

  @Input() message: string;

  @Output() graphLoaded = new EventEmitter<any>();

  @ViewChild(ChartComponent) chart: ChartComponent;

  chartOptions: ChartOptions = {
    type: 'column',
  };
  titleOptions: TitleOptions = {};
  subtitleOptions: SubtitleOptions = {};
  xAxisOptions: XAxisOptions = {
    type: 'category',
    labels: {
      overflow: 'justify',
    },
  };
  yAxisOptions: YAxisOptions = {
    min: 0,
  };
  legendOptions: LegendOptions = {
    align: 'right' as AlignValue,
    verticalAlign: 'middle' as VerticalAlignValue,
    layout: 'vertical' as OptionsLayoutValue,
    backgroundColor: defaultOptions.legend.backgroundColor || 'white',
    borderColor: '#CCC',
    borderWidth: 1,
    shadow: false,
    enabled: false,
  };

  plotOptions: PlotOptions = {};

  series: T[];
  processedSeries: T[];
  tooltipOptions: TooltipOptions;

  abstract downloadTable(options: DownloadTableOptions): void;

  downloadImage(
    documentName?: string,
    exportingOptions: Partial<ExportingOptions> = {},
    chartOptions: Partial<Options> = {},
  ) {
    const name = documentName
      ? `${this.titleOptions.text} (${documentName})`
      : this.titleOptions.text;
    const { chartWidth, chartHeight } = this.chart.chart;
    this.chart.downloadImage(
      {
        filename: name,
        sourceWidth: chartWidth,
        sourceHeight: chartHeight,
        ...exportingOptions,
      },
      chartOptions,
    );
  }

  prepareSeries(data: T[]): T[] {
    return data;
  }

  resize() {
    this.chart.resize();
  }

  onDataChanged(data: any): void {
    this.data = data;
  }
}
